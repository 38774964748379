/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:f01d40f9-a9ca-4389-976d-83b31ce5b2c2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_TwKEjkNQq",
    "aws_user_pools_web_client_id": "2227dp2nc1ml00rthj86mqu2uh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://kprn2vav2nbgre67uojuj4xp4a.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "api6c1c0e7a",
            "endpoint": "https://3fndagjw84.execute-api.us-east-2.amazonaws.com/produs",
            "region": "us-east-2"
        }
    ],
    "aws_content_delivery_bucket": "production-produs",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d1p1w0fuq4l207.cloudfront.net",
    "aws_user_files_s3_bucket": "app5e7c40e10d1d4994b5f95d7b71a6ffc81134933651-produs",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
