<template>
  <div class="mypage-view">
    <!--投稿内容表示エリア（最新投稿）-->
    <div v-show="isPostExisted">
      <div v-for="post in posts" :key="post.id" class="mypage-grid-container">
        <MypagePostItem
          :post="post"
          @delete_post="delete_post(post.id)"
        />
      </div>
    </div>
    <!--投稿内容表示エリア（投稿0件の場合）-->
    <div v-show="isPostNotExisted" class="container">
      <section class="explanation">
        <div class="explanation__points">
          <p>{{$t("mypage.isPostNotExisted")}}</p>
        </div>
      </section>
    </div>


    <div v-if="nextToken" @click="get">
      <img class="post-loading" src="@/assets/img/loading.svg" />
    </div>
  </div>
</template>

<script>
import MypagePostItem from "@/components/local/mypage/MypagePostItem"

import { API, graphqlOperation } from 'aws-amplify'
import {
  listPostsSortByPostedAt,
} from '@/graphql/queries'

import {
  deletePost,
} from '@/graphql/mutations'

export default {
  name: 'MypageView',
  components: {
    MypagePostItem
  },

  // ------------------------------------ props ------------------------------------
  props: {
    // ユーザー（DB）
    user: {
      type: Object,
      require: true
    },
    // 子供（DB）
    children: {
      type: Array,
      require: true
    },
    isLogin: {
      type: Boolean,
      require: true,
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      // 投稿情報
      posts: [],
      nextToken: null,
      loading: false,
      isLoaded: false,
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    // 投稿あり
    isPostExisted() {
      return this.isLoaded && this.posts.length > 0
    },
    // 投稿なし
    isPostNotExisted() {
      return this.isLoaded && this.posts.length == 0
    }
  },

  // ------------------------------------ watch ------------------------------------
  watch: {
    isLogin(now) {
      if(now) {
        this.isLoaded = false;
        this.initialize();
      } else {
        this.isLoaded = false;
        this.posts = []
        this.nextToken = null,
        this.open_login_popup()
      }
    }
  },

  // ------------------------------------ hook ------------------------------------
  created() {
    // 初期処理
    this.initialize()

    // event listener
    window.addEventListener('scroll', async function () {
      const doch = document.body.clientHeight; //ページ全体の高さ
      const winh = window.innerHeight; //ウィンドウの高さ
      const bottom = doch - winh; //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      const currentPos = window.pageYOffset
      if (bottom <= currentPos) {
        //一番下までスクロールした時に実行
        if (this.nextToken && !this.loading){
          this.loading = true
          await this.get()
        }
      }
    }.bind(this));

  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    onLogin(){
      this.isLoaded = false;
      this.initialize();
    },
    onLogout() {
      this.isLoaded = false;
      this.open_login_popup()
    },

    // 初期処理
    initialize: async function(){
      // データ初期化
      this.posts = [];

      // 未ログイン状態の場合、ログインポップアップ表示
      if (!this.isLogin) {
        this.open_login_popup()
        return
      }

      // 検索
      this.get()
    },

    open_login_popup() {
      this.$emit("open-login-popup")
    },

    delete_post: async function(id){
      const is_delete = confirm(this.$i18n.t('mypage.delete_post_confirm'));
      if(is_delete){
        try{
          await API.graphql(graphqlOperation(deletePost, {input:{id: id}})).then(
            this.posts = this.posts.filter(p => p.id !== id)
          )
        } catch(error) {
          console.error('delete',error)
          return
        }
        // ユーザー情報再取得
        this.$emit("reget-user-info")
        this.$toast.info(this.$t('msg.mypage.post_deleted'));
      }
    },

    // マイページ取得
    get: async function(){
      try{
        await API.graphql(graphqlOperation(listPostsSortByPostedAt, {
          userId : this.user.id,
          sortDirection: "DESC",
          limit: 5,
          nextToken: this.nextToken,
        })).then( value => {
          // itemsの中身チェック
          const items = value.data.listPostsSortByPostedAt.items
          this.nextToken = value.data.listPostsSortByPostedAt.nextToken
          this.posts = this.posts.concat(items);
          this.loading = false
        })
      } catch(error) {
        console.error('list',error)
        return
      }
      this.isLoaded = true
    }
  }
}


</script>

<style scoped lang="scss">
.post-loading {
  display: block;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
}
</style>
