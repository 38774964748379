import {createI18n} from 'vue-i18n';
import messages from './messages';
import amplifyVocablaries from "./amplifyVocablaries";
import { I18n } from 'aws-amplify';


const locale = () =>{
    let locale = "en";    // default: "en"
    const language = window.navigator.language;
    if (language) {
        const lang = language.toLowerCase().split("-");
        switch (lang[0]) {
            case "ja":    // 日本語: ja, ja-JP
                // case "es":    // スペイン語: es, es-*
                // case "ko":    // 韓国語
                // case "zhcn":  // 中国語 (簡体)
                // case "zhtw":  // 中国語 (繁体)
                locale = lang[0];
                break;
            // case "zh":    // 中国語: zh-*
            //     switch (lang[1]) {
            //         case "cn":  // 中国
            //         case "sg":  // シンガポール
            //         case "hans":
            //             locale = "zhcn";  // 中国語 (簡体)
            //             break;
            //         case "tw":  // 台湾
            //         case "hk":  // 香港
            //         case "mo":  // マカオ
            //         case "hant":
            //             locale = "zhtw";  // 中国語 (繁体)
            //             break;
            //     }
            //     break;
        }
    }
    return locale;
}

const datetimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'ja': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    }
}


const i18n = createI18n({
    locale: locale(),
    fallbackLocale: "ja",
    messages,
    datetimeFormats,
})

I18n.putVocabularies(amplifyVocablaries);
I18n.setLanguage(locale());

export default i18n;
