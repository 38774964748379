<template>
  <canvas ref="canvas" class="canvas-content"></canvas>
</template>

<script>
import { Storage } from 'aws-amplify'


export default {
  components: {},

  // ------------------------------------ props ------------------------------------
  props: {
    // src
    src: {
      type: String,
      required: true
    },

    // alt
    alt: {
      type: String,
      required: false,
      default: null
    },

    // コメント
    answer: {
      type: String,
      required: false,
      default: null
    },

    question: {
      type: String,
      required: false,
      default: null
    }

  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      imageObject: null,
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {},

  // ------------------------------------ watch ------------------------------------
  watch: {},

  // ------------------------------------ hook ------------------------------------
  mounted() {
    // 初期設定
    this.initializeImage()
  },
  
  // created() {
  //   // インスタンスを作成した後、イベントリスナに登録
  //   window.addEventListener("resize", this.drawText())
  // },

  // beforeUnmount() {
  //   // インスタンスを破棄する前に、イベントリスナから削除
  //   window.removeEventListener("resize", this.drawText())
  // },


  // ------------------------------------ methods ------------------------------------
  methods: {
    // 画像の初期化
    initializeImage: async function() {
      const img = await Storage.get(this.src,{ download: true })
      // 背景画像
      this.imageObject = new Image()
      const url = window.URL || window.webkitURL;

      this.imageObject.src = url.createObjectURL(img.Body);
      this.imageObject.alt = this.alt

      // 背景画像読込後にCanvasに描画
      this.imageObject.onload = () => {   
        this.drawText()
      }
    },

    // テキストの書き出し 
    drawText() {  
      // Canvas準備
      const context = this.$refs.canvas.getContext('2d')

      // キャンバスのサイズ設定
      this.$refs.canvas.width = this.imageObject.width;
      this.$refs.canvas.height = this.imageObject.height;

      // 画像の描画
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.drawImage(this.imageObject, 0, 0);

      // 回答の描画
      if (this.answer) {
        this.drawAnswer(context,this.answer)
      }

      // タイトルの描画
      if (this.question){
        this.drawQuestion(context,this.question)
      }

    },

    drawAnswer(context,answer){

      // 縮尺基準値
      const scaleReferenceValue = 30;
      // 帯高さ倍率
      const labelHeightSlace = 1.3;
      // 行高さ
      const lineHeight = 1.3;
      // 1行あたりの文字数
      const rowStringCnt = this.isSingleByteStrings(answer) ? 56 : 28;

      //入力文字を1文字毎に配列化
      let arrayText = answer.split('');

      // 出力用の配列を用意
      let arrayRow = [];
      arrayRow[0] = '';
      let rowCnt = 0;

      // 入力1文字毎にループ、改行コードもしくは折り返しで配列の添え字を足す
      for (let i = 0; i < arrayText.length; i++){
        let text = arrayText[i];
        if (arrayRow[rowCnt].length >= rowStringCnt){
          rowCnt++;
          arrayRow[rowCnt] = '';
        }
        if(text == "\n"){
          rowCnt++;
          arrayRow[rowCnt] = '';
          text = '';
        }
        arrayRow[rowCnt] += text;
      }

      // フォントサイズ
      const textFontSize = this.imageObject.width / scaleReferenceValue;
      // 帯の高さ
      const labelHeight = arrayRow.length * textFontSize * lineHeight * labelHeightSlace;

      // 帯の挿入
      context.beginPath();
      context.fillStyle = "rgba(" + [50, 50, 50, 0.7] + ")";
      context.fillRect(0, context.canvas.height - labelHeight, context.canvas.width, context.canvas.height);

      // 文字の設定
      const textX = textFontSize;
      const textY = (context.canvas.height - labelHeight)
          + (labelHeight - (textFontSize + (arrayRow.length - 1) * textFontSize * lineHeight)) / 2
      context.fillStyle = 'black'
      context.font = textFontSize + "px 'Noto Sans JP'"
      context.textAlign = "left"
      context.textBaseline = "top"
      context.fillStyle = "white";
      // 文字の表示、y軸とx軸をループする
      for (let i = 0; i < arrayRow.length; i++){
          context.fillText(arrayRow[i], textX, textY + (i * textFontSize * lineHeight));
      }
    },

    drawQuestion(context,question){

      // 縮尺基準値
      const scaleReferenceValue = 30;
      // 帯高さ倍率
      const labelHeightSlace = 1.3;
      // 行高さ
      const lineHeight = 1.3;
      // 1行あたりの文字数
      const rowStringCnt = this.isSingleByteStrings(question) ? 56 : 28;

      //入力文字を1文字毎に配列化
      let arrayText = question.split('');

      // 出力用の配列を用意
      let arrayRow = [];
      arrayRow[0] = '';
      let rowCnt = 0;

      // 入力1文字毎にループ、改行コードもしくは折り返しで配列の添え字を足す
      for (let i = 0; i < arrayText.length; i++){
        let text = arrayText[i];
        if (arrayRow[rowCnt].length >= rowStringCnt){
          rowCnt++;
          arrayRow[rowCnt] = '';
        }
        if(text == "\n"){
          rowCnt++;
          arrayRow[rowCnt] = '';
          text = '';
        }
        arrayRow[rowCnt] += text;
      }

      // フォントサイズ
      const textFontSize = this.imageObject.width / scaleReferenceValue;
      // 帯の高さ
      const labelHeight = arrayRow.length * textFontSize * lineHeight * labelHeightSlace;

      // 帯の挿入
      context.beginPath();
      context.fillStyle = "rgba(" + [50, 50, 50, 0.7] + ")";
      context.fillRect(0, 0, context.canvas.width, labelHeight);

      // 文字の設定
      const textX = textFontSize;
      const textY = (labelHeight - (textFontSize + (arrayRow.length - 1) * textFontSize * lineHeight)) / 2;
      context.fillStyle = 'black'
      context.font = textFontSize + "px 'Noto Sans JP'"
      context.textAlign = "left"
      context.textBaseline = "top"
      context.fillStyle = "white";
      // 文字の表示、y軸とx軸をループする
      for (let i = 0; i < arrayRow.length; i++){
        context.fillText(arrayRow[i], textX, textY + (i * textFontSize * lineHeight));
      }
    },

    isSingleByteStrings(str){
      return str.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~ ]*$/)
    }


  }
}
</script>

<style scoped lang="scss">
.canvas {
  width: 100%;
  height: 100%;
}
</style>
