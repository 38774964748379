<template>
  <div class="login-popup signin">
    <div class="signin__content ">
      <a class="signin__content__close" @click="closePopup">&times;</a>
      <p>{{$t("header.loginPopup.title")}}</p>
      <div class="signin__content__form">
        <input type="text" name="email" class="signin__content__form__email" :class="{error:isError}"
          v-model="email" :placeholder="$t('header.loginPopup.email')">
        <input type="password" name="password" class="signin__content__form__password" :class="{error:isError}"
          v-model="password" :placeholder="$t('header.loginPopup.password')">
        <button class="signin__content__form__send" @click="login" :disabled="login_button_disabled">
          {{$t("header.loginPopup.login")}}
        </button>
      </div>
      <div class="signin__content__options">
        <p>
          {{$t("header.loginPopup.resetL1")}}
          <span class="highlight" @click="gotoPasswordReset">{{$t("header.loginPopup.reset")}}</span>
        </p>
        <p>
          {{$t("header.loginPopup.signupL1")}}
          <span class="highlight" @click="gotoSignup">{{$t("header.loginPopup.signup")}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  components: {},

  // ------------------------------------ props ------------------------------------
  props: {},

  // ------------------------------------ data ------------------------------------
  data() {
    return{
      email: null,
      password: null,
      isError: false
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    login_button_disabled() {
      return !this.email || !this.password
    }
  },

  // ------------------------------------ watch ------------------------------------
  watch: {
    email: function(n){this.email = n.length > 50 ? n.slice(0, -1) : n},
    password: function(n){this.password = n.length > 50 ? n.slice(0, -1) : n},
  },


  // ------------------------------------ hook ------------------------------------
  created() {
    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("keydown", this.onKeydown, false)
  },

  beforeUnmount() {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("keydown", this.onKeydown, false)
  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    // キーダウンイベント
    onKeydown(event) {
      // Escapeボタン押下時
      if (event.key === "Escape") {
        this.closePopup()
      }
    },

    // ポップアップを閉じる
    closePopup: async function(){
      this.$emit("close-popup")
    },

    // パスワードリセットへ
    gotoPasswordReset() {
      this.$emit("goto-passwordreset")
    },

    // 登録へ
    gotoSignup() {
      this.$emit("goto-signup")
    },

    // ログイン完了
    loginCompleted: async function(){
      this.$emit("login-completed")
    },

    // ログイン
    login: async function(){
      this.isError = false
      try {
        await Auth.signIn(this.email, this.password);
      } catch (error) {
        console.error('error signing in', error);
        this.$toast.error(this.$t('msg.login.fail'));
        this.isError = true
        return
      }
      // ログイン完了
      await this.loginCompleted()
      // ポップアップを閉じる
      await this.closePopup()

      this.$toast.info(this.$t('msg.login.success'));
    }
  }
}
</script>

<style scoped lang="scss">
</style>
