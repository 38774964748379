<template>
  <div class="navigation-bar-signout">
    <nav class="nav">
      <figure class="nav__image nav__image--1">
        <a :href="$t('page.extra_url.index')" class="toppage">
          <img class="nav__logo" src="@/assets/img/toybox-logo.png" alt="toybox logo" />
        </a>
      </figure>
      <figure class="nav__image nav__image--2">
        <img class="nav__title" src="@/assets/img/titlelogo.svg" alt="title logo" />
      </figure>
      <div v-if="start_render_content" class="nav__user">        
        <!-- メニュー -->
<!--        <p class="nav__user__logout"  @click="changeMenuState">-->
<!--          メニュー-->
<!--        </p>-->
        <img class="nav__user__icon" src="@/assets/img/menudots.svg" @click="changeMenuState"/>

        <nav class="nav__menu" v-bind:class='{active:isMenuActive}'>
          <ul>
            <li><a @click="openLoginPopup">{{$t("header.login")}}</a></li>
            <li><a @click="openSignupPopup">{{$t("header.signup")}}</a></li>
          </ul>
        </nav>
      </div>
    </nav>

    <!-- ログインポップアップ -->
    <LoginPopup
      v-if="isLoginPopupVislble"
      @close-popup="closeLoginPopup"
      @goto-signup="openSignupPopup"
      @goto-passwordreset="openPasswordResetPopup"
      @login-completed="loginCompleted"
    />

    <!-- サインアップポップアップ-->
    <SignupPopup
      v-if="isSignupPopupVislble"
      @close-popup="closeSignupPopup"
      @goto-login="openLoginPopup"
      @login-completed="loginCompleted"
    />

    <!-- パスワードリセットポップアップ-->
    <PasswordResetPopup
      v-if="isPasswordResetPopupVislble"
      @close-popup="closePasswordResetPopup"
      @goto-login="openLoginPopup"
    />
  </div>
</template>

<script>
import LoginPopup from "@/components/global/login/LoginPopup"
import SignupPopup from "@/components/global/signup/SignupPopup"
import PasswordResetPopup from "@/components/global/passwordReset/PasswordResetPopup"

export default {
  components: {
    LoginPopup,
    SignupPopup,
    PasswordResetPopup
  },

  // ------------------------------------ props ------------------------------------
  props: {
    // ユーザー情報確認済みか？（した後で読み込み）
    start_render_content: {
      type: Boolean,
      require: true
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      // 各ポップアップの表示制御
      isLoginPopupVislble: false,
      isSignupPopupVislble: false,
      isPasswordResetPopupVislble: false,
      // メニュー表示
      isMenuActive: false
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {},

  // ------------------------------------ watch ------------------------------------
  watch: {},

  // ------------------------------------ methods ------------------------------------
  methods: {
    // メニュー表示、非表示の切り替え
    changeMenuState() {
      this.isMenuActive = !this.isMenuActive
    },
    // メニュー非表示
    closeMenu() {
      this.isMenuActive = false
    },
    // ログインインポップアップの開閉
    openLoginPopup() {
      this.closeMenu()
      this.isLoginPopupVislble = true
      this.isSignupPopupVislble = false
      this.isPasswordResetPopupVislble = false
    },
    closeLoginPopup() {
      this.isLoginPopupVislble = false
    },

    // サインアップポップアップの開閉
    openSignupPopup() {
      this.closeMenu()
      this.isSignupPopupVislble = true
      this.isLoginPopupVislble = false
      this.isPasswordResetPopupVislble = false
    },
    closeSignupPopup() {
      this.isSignupPopupVislble = false
    },

    // パスワードリセットポップアップの開閉
    openPasswordResetPopup() {
      this.isPasswordResetPopupVislble = true
      this.isLoginPopupVislble = false
      this.isSignupPopupVislble = false
    },
    closePasswordResetPopup() {
      this.isPasswordResetPopupVislble = false
    },

    // ログイン完了
    loginCompleted() {
      this.$emit("login-completed")
    }
  }
}
</script>

<style scoped lang="scss">
nav.nav__menu {
  display: none;
  position: absolute;
  z-index : 2;
  top  : 3.25em;
  right: 0.75em;
  color: #fff;
  background: #ffad15;
  filter: drop-shadow(0 0 2px #b3b3b3);
  text-align: center;
  width: 12.5em;
}
nav.nav__menu ul {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
nav.nav__menu ul li {
  list-style-type: none;
  padding: 0;
  width: 100%;
}
nav.nav__menu ul li:last-child {
  padding-bottom: 0;
}
nav.nav__menu ul li:hover{
  background :#ddd;
}
nav.nav__menu ul li a {
  display: block;
  color: #fff;
  padding: 1em 0;
  text-decoration :none;
}
nav.nav__menu.active {
  display: block;
}
</style>
