export default {
  page: {
    mypage: {
      title: "toyboxマイページ",
      description: "toyboxマイページ",
    },
    dummy: {
      title: "ダミーページ",
      description: "ダミーページ",
    },
    editPictureBook: {
      title: "絵本編集ページ",
      description: "絵本編集ページ",
    },
    extra_url: {
      index: "https://www.toybox.fun/jp/",
      pictureBook: "https://www.toybox.fun/jp/picturebook/",
      disclaimer: "https://toybox.fun/jp/disclaimer_copyright/",
      privacy_policy: "https://toybox.fun/jp/privacy_policy/",
    },
  },
  title: {
    lp: "LP",
    myPage: "マイページ",
    editPictureBook: "絵本編集ページ",
  },
  common: {
    404: "404 ページが見つかりません",
  },
  header: {
    userInfo: "ユーザー情報",
    mailAddressUpdate: "メールアドレス更新",
    logout: "ログアウト",
    login: "ログイン",
    signup: "登録",
    signupPopup: {
      title: "「ライクログ」に登録する",
      lastname: "姓",
      firstname: "名",
      email: "メールアドレス",
      nickname: "ユーザー名（ニックネーム）",
      password: "パスワード（8文字以上）",
      title2: "お子様の名前を登録する",
      add: "追加",
      agreement1: "",
      agreementPrivacyPolicy: "プライバシーポリシー",
      agreementDisclaimer: "免責事項と著作権",
      agreement2: "に同意",
      signup: "ユーザー登録",
      loginL1: "ユーザー登録がお済みの場合：",
      login: "ログイン",
      title3: "認証コードを入力する",
      verifyCode: "認証コード",
      verify: "認証",
      sentcode: "認証コードを送信しました。メールをご確認の上入力ください。",
    },
    loginPopup: {
      title: "「ライクログ」にログインする",
      email: "メールアドレス",
      password: "パスワード",
      login: "ログイン",
      resetL1: "パスワードを忘れた場合：",
      reset: "リセット",
      signupL1: "ユーザー登録がお済みでない場合：",
      signup: "登録",
    },
    passwordResetPopup: {
      title1: "パスワードをリセットする",
      caption1_1: "メールアドレスを入力してください。",
      email: "メールアドレス",
      sendResetCode: "リセットコードを送る",
      login: "ログインに戻る",

      title2: "パスワードをリセットする",
      caption2_1:
        "メールをご確認の上、リセットコードと新パスワードを入力してください",
      resetCode: "リセットコード",
      newPassword: "新パスワード",
      reset: "リセットする",
    },
    userinfoPopup: {
      title1: "ユーザー情報を更新する",
      lastname: "姓",
      firstname: "名",
      nickname: "ユーザー名（ニックネーム）",
      title2: "お子様の名前を更新する",
      add: "追加",
      update: "更新",
      childname: "子供の名前",
      delete: "削除",
    },
    mailAddressPopup: {
      title1: "更新後の",
      caption1_1: "メールアドレスを入力してください。",
      email: "メールアドレス",
      sendVerifyCode: "認証コードを送る",
      login: "ログインに戻る",
      title2: "認証コードを入力する",
      caption2_1: "メールをご確認の上、認証コードを入力してください",
      verifyCode: "認証コード",
      update: "更新する",
    },
  },
  footer: {
    topPage: "©toyboxの絵本",
    disclaimerCopyright: "免責事項と著作権",
    privacyPolicy: "プライバシーポリシー",
  },
  lp: {
    explanation: "気持ちをメモしてシェアする",
    emotionTitle: "どんな気持ち？",
    upliftMeterTitle: "テンションの変化は？",
    upliftZero: "変わらない",
    submitToMyPage: "マイページに保存",
    signup: "登録",
    // login: "登録済みの方はこちら",
    login: "ログインする",
    popup: {
      title: "基本アイコンから選ぶ",
      detail: "細かく選ぶ",
      like: "好き？",
      happy: "うれしい？",
      enjoying: "たのしい？",
      heart: "こころは？",
      think: "どう思う？",
      new: "おどろいた？",
      spirit: "やる気は？",
      ready: "やれそう？",
      result: "結果はどう？",
      self: "じぶんは？",
      neutral: "ふつうかな？",
    },

    comment: {
      title: "問いかけ",
      parent: "お母さん、お父さんのコメント",
      changeParent: "大人向けの質問に変える",
      free: "自由にコメント",
      changeChild: "子供向けの質問に変える",
      // insteadTitle2: "代理で記録",
      insteadTitle2: "誰の記録ですか？",
      representative: "{name}の記録",
    },
  },
  manual: {
    title: "アプリの使い方",
    step1: {
      title: "1. 気持ちをメモる",
      l1: "読み聞かせの後に、問いかけをして、子供の反応をメモします",
      l2:
        "子供の気持を「気持ちアイコン」で選択。テンションの変化もスライダーで選ぶ",
      l3: "「マイページに保存」を押す",
    },
    step2: {
      title: "2. マイページに保存",
      l1: "子供の反応が埋め込まれた絵本の絵が保存されます",
      l2: "何度でも新しい投稿を追加できます",
    },
    step3: {
      title: "3. シェアする",
      l1:
        "ボタンひとつで、twitterとfacebookに投稿できます。twitterではリンク画像の読み込みが完了してから投稿してください",
      l2:
        "画像をダウンロードし、お好きなSNSに自由に投稿することもできます。よかったら「#toybox」をつけてくださいね",
    },
  },
  mypage: {
    representative: "{name}の記録",
    download: "投稿ダウンロード",
    delete_post: "投稿削除",
    delete_post_confirm: "投稿を削除しますがよろしいですか？",
    isPostNotExisted: "投稿は0件です",
    emotionalPoint: "気持ち変化",
  },
  share: {
    post: "#toybox\n",
  },
  msg: {
    signup: {
      failSignup: "サインアップに失敗しました。{err}",
      failVerify: "サインアップの認証に失敗しました。{err}",
      accountExists: "アカウントがすでに存在しています。",
    },
    login: {
      success: "ログインが完了しました。",
      fail: "ログインに失敗しました。",
    },
    logout: {
      success: "ログアウトが完了しました。",
    },
    passwordReset: {
      success: "パスワードのリセットが完了しました。",
      failSendResetCode: "リセットコードの送信に失敗しました。",
      failResetPassword: "パスワードのリセットに失敗しました。",
    },
    userinfo: {
      updated: "ユーザー情報の更新が完了しました",
    },
    mailAddress: {
      notChanged: "「{mail}」は現状設定されているメールアドレスです。",
      success: "メールアドレスの更新が完了しました。{mail}",
      failSendVerifyCode: "認証コードの送信に失敗しました。",
      failUpdateMailAddress: "メールアドレスの更新に失敗しました。",
    },
    lp: {
      posted: "投稿が完了しました。",
      selectChild: "子供を選択してください",
    },
    mypage: {
      post_deleted: "投稿を削除しました。",
    },
  },
};
