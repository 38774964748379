<template>
  <section class="lp-emotion-popup popup-emo popupEmo">
    <div class="popup-emo__content">
      <p class="popup-emo__content__basic">{{$t("lp.popup.title")}}</p>
      <img
      src="@/assets/img/emoji/LOVE.png"
      alt="love" class="popup-emo__content__img cursor-pointer loveBasic" @click="selectEmotionalType('LOVE')"/>
      <img
      src="@/assets/img/emoji/HATE.png"
      alt="hate" class="popup-emo__content__img cursor-pointer hateBasic" @click="selectEmotionalType('HATE')"/>
      <img
      src="@/assets/img/emoji/VERYHAPPY.png"
      alt="veryhappy" class="popup-emo__content__img cursor-pointer veryhappyBasic" @click="selectEmotionalType('VERYHAPPY')"/>
      <img
      src="@/assets/img/emoji/EXCITED.png"
      alt="excited" class="popup-emo__content__img cursor-pointer excitedBasic" @click="selectEmotionalType('EXCITED')"/>
      <img
      src="@/assets/img/emoji/SAD.png"
      alt="sad" class="popup-emo__content__img cursor-pointer sadBasic" @click="selectEmotionalType('SAD')"/>
      <img
      src="@/assets/img/emoji/ANGRY.png"
      alt="angry" class="popup-emo__content__img cursor-pointer angryBasic" @click="selectEmotionalType('ANGRY')"/>

      <p class="popup-emo__content__expanded">{{$t("lp.popup.detail")}}</p>
      <p class="popup-emo__content__like">{{$t("lp.popup.like")}}</p>
      <img
      src="@/assets/img/emoji/LOVE.png"
      alt="love" class="popup-emo__content__img cursor-pointer love" @click="selectEmotionalType('LOVE')"/>
      <img
      src="@/assets/img/emoji/LIKE.png"
      alt="like" class="popup-emo__content__img cursor-pointer like" @click="selectEmotionalType('LIKE')"/>
      <img
      src="@/assets/img/emoji/NOTLIKE.png"
      alt="notlike" class="popup-emo__content__img cursor-pointer notlike" @click="selectEmotionalType('NOTLIKE')"/>
      <img
      src="@/assets/img/emoji/HATE.png"
      alt="hate" class="popup-emo__content__img cursor-pointer hate" @click="selectEmotionalType('HATE')"/>

      <p class="popup-emo__content__happy">{{$t("lp.popup.happy")}}</p>
      <img
      src="@/assets/img/emoji/VERYHAPPY.png"
      alt="veryhappy" class="popup-emo__content__img cursor-pointer veryhappy" @click="selectEmotionalType('VERYHAPPY')"/>
      <img
      src="@/assets/img/emoji/HAPPY.png"
      alt="happy" class="popup-emo__content__img cursor-pointer happy" @click="selectEmotionalType('HAPPY')"/>
      <img
      src="@/assets/img/emoji/SAD.png"
      alt="sad" class="popup-emo__content__img cursor-pointer sad" @click="selectEmotionalType('SAD')"/>
      <img
      src="@/assets/img/emoji/VERYSAD.png"
      alt="verysad" class="popup-emo__content__img cursor-pointer verysad" @click="selectEmotionalType('VERYSAD')"/>

      <p class="popup-emo__content__enjoying">{{$t("lp.popup.enjoying")}}</p>
      <img
      src="@/assets/img/emoji/EXCITED.png"
      alt="excited" class="popup-emo__content__img cursor-pointer excited" @click="selectEmotionalType('EXCITED')"/>
      <img
      src="@/assets/img/emoji/ENJOYING.png"
      alt="enjoying" class="popup-emo__content__img cursor-pointer enjoying" @click="selectEmotionalType('ENJOYING')"/>
      <img
      src="@/assets/img/emoji/FRUSTRATED.png"
      alt="frustrated" class="popup-emo__content__img cursor-pointer frustrated" @click="selectEmotionalType('FRUSTRATED')"/>
      <img
      src="@/assets/img/emoji/ANGRY.png"
      alt="angry" class="popup-emo__content__img cursor-pointer angry" @click="selectEmotionalType('ANGRY')"/>

      <p class="popup-emo__content__heart">{{$t("lp.popup.heart")}}</p>
      <img
      src="@/assets/img/emoji/WARM.png"
      alt="warm" class="popup-emo__content__img cursor-pointer warm" @click="selectEmotionalType('WARM')"/>
      <img
      src="@/assets/img/emoji/BROKEN.png"
      alt="broken" class="popup-emo__content__img cursor-pointer broken" @click="selectEmotionalType('BROKEN')"/>

      <p class="popup-emo__content__think">{{$t("lp.popup.think")}}</p>
      <img
      src="@/assets/img/emoji/INSPIRED.png"
      alt="inspired" class="popup-emo__content__img cursor-pointer inspired" @click="selectEmotionalType('INSPIRED')"/>
      <img
      src="@/assets/img/emoji/INTERESTED.png"
      alt="interested" class="popup-emo__content__img cursor-pointer interested" @click="selectEmotionalType('INTERESTED')"/>
      <img
      src="@/assets/img/emoji/WONDERING.png"
      alt="wondering" class="popup-emo__content__img cursor-pointer wondering" @click="selectEmotionalType('WONDERING')"/>
      <img
      src="@/assets/img/emoji/CONFUSED.png"
      alt="confused" class="popup-emo__content__img cursor-pointer confused" @click="selectEmotionalType('CONFUSED')"/>

      <p class="popup-emo__content__new">{{$t("lp.popup.new")}}</p>
      <img
      src="@/assets/img/emoji/SURPRISED.png"
      alt="surprised" class="popup-emo__content__img cursor-pointer surprised" @click="selectEmotionalType('SURPRISED')"/>
      <img
      src="@/assets/img/emoji/BORED.png"
      alt="bored" class="popup-emo__content__img cursor-pointer bored" @click="selectEmotionalType('BORED')"/>

      <p class="popup-emo__content__spirit">{{$t("lp.popup.spirit")}}</p>
      <img
      src="@/assets/img/emoji/FIREDUP.png"
      alt="firedup" class="popup-emo__content__img cursor-pointer firedup" @click="selectEmotionalType('FIREDUP')"/>
      <img
      src="@/assets/img/emoji/BUSY.png"
      alt="busy" class="popup-emo__content__img cursor-pointer busy" @click="selectEmotionalType('BUSY')"/>
      <img
      src="@/assets/img/emoji/TIRED.png"
      alt="tired" class="popup-emo__content__img cursor-pointer tired" @click="selectEmotionalType('TIRED')"/>
      <img
      src="@/assets/img/emoji/DEPRESSED.png"
      alt="depressed" class="popup-emo__content__img cursor-pointer depressed" @click="selectEmotionalType('DEPRESSED')"/>

      <p class="popup-emo__content__ready">{{$t("lp.popup.ready")}}</p>
      <img
      src="@/assets/img/emoji/COURAGE.png"
      alt="courage" class="popup-emo__content__img cursor-pointer courage" @click="selectEmotionalType('COURAGE')"/>
      <img
      src="@/assets/img/emoji/FINE.png"
      alt="fine" class="popup-emo__content__img cursor-pointer fine" @click="selectEmotionalType('FINE')"/>
      <img
      src="@/assets/img/emoji/WORRIED.png"
      alt="worried" class="popup-emo__content__img cursor-pointer worried" @click="selectEmotionalType('WORRIED')"/>
      <img
      src="@/assets/img/emoji/PANIC.png"
      alt="panic" class="popup-emo__content__img cursor-pointer panic" @click="selectEmotionalType('PANIC')"/>

      <p class="popup-emo__content__result">{{$t("lp.popup.result")}}</p>
      <img
      src="@/assets/img/emoji/SUCCESS.png"
      alt="success" class="popup-emo__content__img cursor-pointer success" @click="selectEmotionalType('SUCCESS')"/>
      <img
      src="@/assets/img/emoji/MADEIT.png"
      alt="madeit" class="popup-emo__content__img cursor-pointer made" @click="selectEmotionalType('MADEIT')"/>
      <img
      src="@/assets/img/emoji/MISSED.png"
      alt="missed" class="popup-emo__content__img cursor-pointer missed" @click="selectEmotionalType('MISSED')"/>
      <img
      src="@/assets/img/emoji/FAIL.png"
      alt="fail" class="popup-emo__content__img cursor-pointer fail" @click="selectEmotionalType('FAIL')"/>

      <p class="popup-emo__content__self">{{$t("lp.popup.self")}}</p>
      <img
      src="@/assets/img/emoji/PROUD.png"
      alt="proud" class="popup-emo__content__img cursor-pointer proud" @click="selectEmotionalType('PROUD')"/>
      <img
      src="@/assets/img/emoji/SHY.png"
      alt="shy" class="popup-emo__content__img cursor-pointer shy" @click="selectEmotionalType('SHY')"/>
      <img
      src="@/assets/img/emoji/JEALOUS.png"
      alt="jealous" class="popup-emo__content__img cursor-pointer jealous" @click="selectEmotionalType('JEALOUS')"/>
      <img
      src="@/assets/img/emoji/ENVIOUS.png"
      alt="envious" class="popup-emo__content__img cursor-pointer envious" @click="selectEmotionalType('ENVIOUS')"/>

      <p class="popup-emo__content__neutral">{{$t("lp.popup.neutral")}}</p>
      <img
      src="@/assets/img/emoji/NEUTRAL.png"
      alt="neutral" class="popup-emo__content__img cursor-pointer neutral" @click="selectEmotionalType('NEUTRAL')"/>
      <a class="popupEmoClose popup-emo__close popup-emo__content__close" @click="closePopup">&times;</a>
    </div>
  </section>
</template>

<script>
import {  
  EmotionalType
} from '@/models/index'

export default {
  components: {},

  // ------------------------------------ props ------------------------------------
  props: {},

  // ------------------------------------ data ------------------------------------
  data() {
    return {}
  },

  // ------------------------------------ computed ------------------------------------
  computed: {},

  // ------------------------------------ watch ------------------------------------
  watch: {},

  // ------------------------------------ methods ------------------------------------
  methods: {
    selectEmotionalType(value) {
      const emotionalType = EmotionalType[value]
      this.$emit("select-emotional-type", emotionalType)
      this.$emit("close-popup")
    },
    closePopup() {
      this.$emit("close-popup")
    },
  }
}
</script>

<style scoped lang="scss">
</style>
