<template>
  <div class="userinfo-popup-child-item userinfo__content__form2__item">
    <input
      type="text" name="child" maxlength="10" class="userinfo__content__form2__child" :value="child.name" :placeholder="$t('header.userinfoPopup.childname')"
      @input="onInputText"
    />
    <div v-if="isDeleteButtonVisibled" class="userinfo__content__form2__delete_icon" @click="del">&times;</div>
  </div>
</template>

<script>

export default {
  components: {},

  // ------------------------------------ props ------------------------------------
  props: {
    child: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {}
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    isDeleteButtonVisibled() {
      // 先頭は削除不可
      if (this.index == 0) return false
      // 投稿がある場合は削除不可
      if (this.child && this.child.hasPosts) return false
        
      // それ以外はOK
      return true
    }
  },

  // ------------------------------------ watch ------------------------------------
  watch: {},

  // ------------------------------------ hook ------------------------------------

  // ------------------------------------ methods ------------------------------------
  methods: {
    // 入力内容の変更
    onInputText(e) {
      const n = e.target.value;
      const name = n.length > 10 ? n.slice(0, -1) : n;
      this.$emit("onInputText",name);
    },
    // 削除
    del() {
      this.$emit("del");
    },
  }
}
</script>

<style scoped lang="scss"></style>
