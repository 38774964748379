<template>
  <div class="main-view">
    <div class="local">
      <h3>※ 管理者専用画面</h3>
      <h3 v-show="isLogin && !isAdmin">管理者ユーザではありません。管理者ユーザでログインして下さい。</h3>
    </div>
    <div v-show="isLogin && isAdmin" class="local">
      <Form @submit="entry">
        <h2>絵本登録</h2>
        <table class="entry">
          <tbody>
          <tr>
            <td>
              <p>ID:</p>
            </td>
            <td>
              <p>{{bookInfo.id}}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>LPコード(質問コード)</p>
              <p>(必須):</p>
            </td>
            <td>
              <Field name="questionCode" v-model="bookInfo.questionCode" placeholder="LPコード(質問コード)" :rules="'required|alpha_num|max:10'"/>
              <div v-on:click="getRec" class="comment__form__switch">DB取得</div>
            </td>
          </tr>
          <tr>
            <td><p>絵本名称(必須):</p></td>
            <td><Field name="name" v-model="bookInfo.name" placeholder="絵本名称" :rules="'required'" /></td>
          </tr>
          <tr>
            <td><p>picturePath:</p></td>
            <td>
              <Field name="picturePath" v-model="bookInfo.picturePath" placeholder="sample.jpg"/>
            </td>
          </tr>
          <tr v-if="bookInfo.picturePath">
            <td>fileupload:</td>
            <td>
              <input name="fileupload" type="file"  @change="upload" />
            </td>
          </tr>
          <tr>
            <td><p>子P問い1:</p></td>
            <td><Field name="basicQuestion" v-model="bookInfo.basicQuestion" placeholder="子P問い1"/></td>
          </tr>
          <tr>
            <td><p>子P問い1回答例:</p></td>
            <td><Field name="exampleBasicQuestion" v-model="bookInfo.exampleBasicQuestion" placeholder="子P問い1回答例"/></td>
          </tr>
          <tr>
            <td><p>子P問い2:</p></td>
            <td>        <Field name="freeCommentQuestion" v-model="bookInfo.freeCommentQuestion" placeholder="例：お母さん、お父さんのコメント"/></td>
          </tr>
          <tr>
            <td><p>子P問い2回答例:</p></td>
            <td><Field name="exampleFreeComment" v-model="bookInfo.exampleFreeComment" placeholder="子P問い2回答例"/></td>
          </tr>
          <tr>
            <td><p>親P問い1:</p></td>
            <td>        <Field name="parentQuestion" v-model="bookInfo.parentQuestion" placeholder="親P問い1"/></td>
          </tr>
          <tr>
            <td><p>親P問い1回答例:</p></td>
            <td><Field name="exampleParentQuestion" v-model="bookInfo.exampleParentQuestion" placeholder="親P問い1回答例"/></td>
          </tr>
          <tr>
            <td><p>親P問い2:</p></td>
            <td>        <Field name="parentCommentQuestion" v-model="bookInfo.parentCommentQuestion" placeholder="親P問い2"/></td>
          </tr>
          <tr>
            <td><p>親P問い2回答例:</p></td>
            <td><Field name="exampleParentComment" v-model="bookInfo.exampleParentComment" placeholder="親P問い2回答例"/></td>
          </tr>
        </tbody>
      </table>

        <ErrorMessage class="error" name="questionCode"/><br>
        <ErrorMessage class="error" name="name"/>
        <br>

        <button>登録/更新</button>
      </Form>

      <hr/>
      <button v-on:click="get">一覧取得</button>
      <div v-if="books.length!=0">
      <br>
      <h2>絵本一覧</h2>
      <br>

      <table class="list">
        <thead>
          <tr>
            <th>id</th>
            <th>質問コード</th>
            <th>name</th>
            <th>子P問い1</th>
            <th>回答例</th>
            <th>子P問い2</th>
            <th>回答例</th>
            <th>親P問い1</th>
            <th>回答例</th>
            <th>親P問い2</th>
            <th>回答例</th>
            <th>ファイル名</th>
            <th>画像</th>
            <th>Link</th>
            <th>更新日</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="book in books" :key="book.id">
          <td>{{ book.id }}</td>
          <td>{{ book.questionCode }}</td>
          <td>{{ book.name }}</td>
          <td>{{ book.basicQuestion }}</td>
          <td>{{ book.exampleBasicQuestion }}</td>
          <td>{{ book.freeCommentQuestion }}</td>
          <td>{{ book.exampleFreeComment }}</td>
          <td>{{ book.parentQuestion }}</td>
          <td>{{ book.exampleParentQuestion }}</td>
          <td>{{ book.parentCommentQuestion }}</td>
          <td>{{ book.exampleParentComment }}</td>
          <td>{{ book.picturePath }}</td>
          <td>
            <amplify-s3-image :img-key="book.picturePath"/>
          </td>
          <td><a target="_blank" :href="'/pb/'+book.questionCode">/pb/{{book.questionCode}}</a></td>
          <td>{{ book.updatedAt }}</td>
        </tr>
        </tbody>
      </table>
      </div>

    </div>

  </div>

</template>

<script>

import {
  // Auth,
  API,
  Storage
} from 'aws-amplify'
import {
  createPictureBook,
  updatePictureBook,
} from '@/graphql/mutations'
import {
  listPictureBooks,
} from '@/graphql/queries'
import { Field, Form, ErrorMessage } from 'vee-validate';
import { defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import { Auth } from 'aws-amplify';

Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});


const intialPictureBook = () => {
  return {
    name: '',
    questionCode:'',
    picturePath: '',
    basicQuestion: '',
    parentQuestion: '',
    freeCommentQuestion: '',
    parentCommentQuestion: '',
    exampleBasicQuestion:'',
    exampleFreeComment:'',
    exampleParentQuestion:'',
    exampleParentComment:'',
  }
}

export default {
  name: 'EditPictureBook',
  components: {
    Field,
    Form,
    ErrorMessage,
  },

  props: {
    // ユーザー（DB）
    isLogin: {
      type: Boolean,
      require: true,
    }
  },

  data () {
    return {
      bookInfo: intialPictureBook(),
      books: "",
      isAdmin: false,
      tempQuestionCode: "",
    }
  },

  watch: {
    isLogin(now) {
      console.log(now);
      if(now) {
        console.log("onLogin")
        this.initialize();
      } else {
        console.log("onLogout")
      }
    }
  },

  created() {
    // 初期処理
    this.initialize()
  },

  methods: {
    // 初期処理
    initialize: async function() {
      console.log("initialize")

      // 未ログイン状態の場合、ログインポップアップ表示
      if (!this.isLogin) {
        this.$emit("open-login-popup")
        return
      }

      const user =  await Auth.currentAuthenticatedUser();
      const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
      if(groups){
        this.isAdmin = groups.includes('admin'); // true
      }

    },

    getPictureBook: async function(){
      return await API.graphql(
          {
            query: listPictureBooks,
            variables: {
              filter:{
                questionCode: {
                  eq: this.bookInfo.questionCode
                }
              }
            },
            authMode: "AMAZON_COGNITO_USER_POOLS"
          }).then( value => {
            return value.data.listPictureBooks.items
        })
    },

    getRec: async function(){
      try{
          const items = await this.getPictureBook()
          if (items.length == 1) {
            const book = items[0];
            this.bookInfo.id = book.id
            this.bookInfo.name = book.name
            this.bookInfo.picturePath = book.picturePath
            this.bookInfo.basicQuestion = book.basicQuestion
            this.bookInfo.parentQuestion = book.parentQuestion
            this.bookInfo.freeCommentQuestion = book.freeCommentQuestion
            this.bookInfo.parentCommentQuestion = book.parentCommentQuestion
            this.bookInfo.exampleBasicQuestion = book.exampleBasicQuestion
            this.bookInfo.exampleFreeComment = book.exampleFreeComment
            this.bookInfo.exampleParentQuestion = book.exampleParentQuestion
            this.bookInfo.exampleParentComment = book.exampleParentComment
            this.tempQuestionCode = book.questionCode
          } else if(items.length > 1) {
            this.$toast.error("質問コードが不正です。（複数件レコードあり）");
          }else{
            this.$toast.error("質問コードのレコードが存在しません。");
          }
      } catch(error) {
        this.$toast.error(error)
        return
      }
    },

    upload(e) {
      var files = e.target.files || e.dataTransfer.files;
      console.log(files)
        // acl: 'public-read',
      Storage.put( this.bookInfo.picturePath, files[0],{
        contentType: "image/jpeg", // contentType is optional
      })
          .then(result => {
            console.log(result)
            // this.refresh()
          })
          .catch(err => {
            console.log(err)
            return
          });
    },

    get: async function(){
      try{
        this.books = [];
        await API.graphql(
            {
              query: listPictureBooks,
              authMode: "AMAZON_COGNITO_USER_POOLS"
            }).then( items => {
              this.books = items.data.listPictureBooks.items
              console.log(items)
            })
      } catch(error) {
            console.error('list',error)
            return
      }

    },

    entry: async function(){
      console.log('book info',this.bookInfo)
      const qc = this.bookInfo.questionCode
      const books = await this.getPictureBook()
      if(!this.bookInfo.id || (this.bookInfo.id && this.tempQuestionCode != this.bookInfo.questionCode)) {
        if (books.length >= 1) {
          this.$toast.error("すでに登録されている、質問コードです。");
          return
        }
      }

      await API.graphql(
          {
            query: this.bookInfo.id ? updatePictureBook: createPictureBook,
            variables: {input: this.bookInfo},
            authMode: "AMAZON_COGNITO_USER_POOLS"
          }
      ).catch(
          err => {
            console.error('entry error',err)
            this.$toast.error("登録に失敗しました");
            return
          }
      )

      this.reset()
      this.$toast.info("登録完了しました。質問コード："+ qc);
    },
    reset : function() {
      this.bookInfo = {}
      Object.assign(this.bookInfo, intialPictureBook());
      this.tempQuestionCode = '';
    },
  }
}
</script>

<style scoped lang="scss">
.local {
  text-align: center;
  font-size: 20px;
}

amplify-s3-image {
  --height: 50px;
  --width: 50px;
}

.comment__form__switch{
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  width: 7rem;
  padding: 0.2rem;
}

span.error {
  font-size: 10px;
  color: red;
}

table {
  margin-left: auto;
  margin-right: auto;

  &.list{
    font-size: 8px;
    margin-bottom: 50px;
  }

  &.entry{
    font-size: 12px;
  }

  th {
    border: 1px dotted;
  }
  td {
    padding: 1px;
    border: 1px dotted;
  }
}

</style>