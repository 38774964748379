<template>
  <div class="mailaddress-popup mailaddress">
    <!-- メールアドレス更新 -->
    <div class="mailaddress__content">
      <a class="mailaddress__content__close" @click="closePopup">&times;</a>

      <div v-if="mailaddress_visibled" class="mailaddress__content__mailaddress">
        <p>{{$t("header.mailAddressPopup.title1")}}</p>
        <p>{{$t("header.mailAddressPopup.caption1_1")}}</p>
        <div class="mailaddress__content__mailaddress__area">
          <div class="mailaddress__content__mailaddress__form1">
            <input type="email" name="email" class="mailaddress__content__mailaddress__form1__email" :class="{error:isError}"
              v-model="email" :placeholder="$t('header.mailAddressPopup.email')"/>
            <button class="mailaddress__content__mailaddress__form1__reset" @click="sendVerifyCode" :disabled="!email">{{$t("header.mailAddressPopup.sendVerifyCode")}}</button>
          </div>
        </div>
      </div>

      <div v-if="verify_visibled" class="mailaddress__content__verify">
        <p>{{$t("header.mailAddressPopup.title2")}}</p>
        <p>{{$t("header.mailAddressPopup.caption2_1")}}</p>
        <div class="mailaddress__content__verify__area">
          <div class="mailaddress__content__verify__form">
            <input type="text" name="code" maxlength="6" class="mailaddress__content__verify__form__verifycode" :class="{error:isError}"
              v-model="code" :placeholder="$t('header.mailAddressPopup.verifyCode')"/>
            <button class="mailaddress__content__verify__form__verify" @click="verifyAndUpdate" :disabled="!code">
              {{$t("header.mailAddressPopup.update")}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth, API} from 'aws-amplify'
import {
  updateUser
} from '@/graphql/mutations'

export default {
  components: {},

  // ------------------------------------ props ------------------------------------
  props: {
    // ユーザー（DB）
    user: {
      type: Object,
      require: true
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      mode: null,
      email: null,
      sent_email: null,
      code: null,
      isError: false
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    mailaddress_visibled() {
      return this.mode === "mailaddress"
    },
    verify_visibled() {
      return this.mode === "verify"
    }
  },

  // ------------------------------------ watch ------------------------------------
  watch: {},


  // ------------------------------------ hook ------------------------------------
  created() {
    // モード
    this.mode = "mailaddress"

    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("keydown", this.onKeydown, false)
  },

  beforeUnmount() {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("keydown", this.onKeydown, false)
  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    // キーダウンイベント
    onKeydown(event) {
      // Escapeボタン押下時
      if (event.key === "Escape") {
        this.closePopup()
      }
    },

    // 更新完了
    mailAddressUpdateCompleted: async function(){
      this.$emit("mail-address-update-completed")
    },

    // ポップアップを閉じる
    closePopup: async function(){
      this.$emit("close-popup")
    },

    // モード変更
    changeMode: async function(mode) {
      this.mode = mode
    },

    // 認証コード送信
    sendVerifyCode: async function(){
      this.isError = false
      this.sent_email = this.email

      const apiName = 'api6c1c0e7a'; // replace this with your api name.
      const path = '/change-email'; //replace this with the path you have configured on your API

      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()
      const user = await Auth.currentAuthenticatedUser()
      // const token = (await Auth.currentSession()).getIdToken().getJwtToken();

      if (user.attributes.email === this.sent_email) {
        this.$toast.error(this.$t('msg.mailAddress.notChanged', {mail : this.sent_email}))
        this.isError = true
        return
      }

      const myInit = {
        body: {
          newEmail: this.sent_email,
          accessToken: currentAuthenticatedUser.signInUserSession.accessToken.jwtToken,
          userPoolId: currentAuthenticatedUser.pool.userPoolId
        },
        headers: {},
      };
      API
        .post(apiName, path, myInit)
        .then(() => {
          this.changeMode("verify")
        })
        .catch(error => {
          console.error('error signing in', error);
          this.$toast.error(this.$t('msg.mailAddress.failSendVerifyCode'));
          this.isError = true
          return
        });
    },

    // 認証＆更新
    verifyAndUpdate: async function(){   
      this.isError = false   
      const apiName = 'api6c1c0e7a';
      const path = '/change-email-confirm';

      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser()

      const myInit = {
        body: {
          code: this.code,
          newEmail: this.sent_email,
          accessToken: currentAuthenticatedUser.signInUserSession.accessToken.jwtToken,
          userPoolId: currentAuthenticatedUser.pool.userPoolId
        },
        headers: {},
      };

      const success = this.$t('msg.mailAddress.success',{mail:this.sent_email})
      const failed = this.$t('msg.mailAddress.failUpdateMailAddress')
      API
        .post(apiName, path, myInit)
        .then(async() => {
          // ユーザー更新
          try{
            let user = {
              id: this.user.id,
              mailAddress: this.sent_email,
            }
            await API.graphql(
              {
                query: updateUser,
                variables: {input: user},
                authMode: "AMAZON_COGNITO_USER_POOLS"
              }
            )
          } catch (error) {
            console.error('dynamo entry error', error)
            this.isError = true
            return
          }
          await Auth.currentAuthenticatedUser({ bypassCache: true });

          // 更新完了
          await this.mailAddressUpdateCompleted();
          // ポップアップを閉じる
          await this.closePopup();
          this.$toast.info(success);
        })
        .catch(error => {
          console.error('error change email',error);
          this.$toast.error(failed);
          this.isError = true
          return
        });

    }
  }
}
</script>

<style scoped lang="scss">
</style>
