<template>
  <div class="signup-popup signup">
    <!-- サインアップ -->
    <div class="signup__content">
      <a class="signup__content__close" @click="closePopup">&times;</a>

      <div v-if="signup_visibled" class="signup__content__signup">
        <p>{{$t("header.signupPopup.title")}}</p>
        <div class="signup__content__signup__area">
          <div class="signup__content__signup__form1">
            <input type="text" name="lastname" maxlength="15" class="signup__content__signup__form1__lastname" v-model="lastname" :placeholder="$t('header.signupPopup.lastname')">
            <input type="text" name="firstname" maxlength="15" class="signup__content__signup__form1__firstname" v-model="firstname" :placeholder="$t('header.signupPopup.firstname')">
            <input type="email" name="email" maxlength="80" class="signup__content__signup__form1__email" v-model="email" :placeholder="$t('header.signupPopup.email')">
            <input type="text" name="username" maxlength="15" class="signup__content__signup__form1__username" v-model="nickname" :placeholder="$t('header.signupPopup.nickname')">
            <input type="password" name="password" maxlength="50" class="signup__content__signup__form1__password" v-model="password" :placeholder="$t('header.signupPopup.password')">
          </div>

          <p>{{$t("header.signupPopup.title2")}}</p>
          <div class="signup__content__signup__form2">
            <div v-for="(child, index) in children" :key="index">
              <UserinfoPopupChildItem
                :child="child"
                :index="index"
                @onInputText="child.name = $event"
                @del="del(index)"
              />
            </div>
            <div class="signup-popup-child-item">
              <button class="signup__content__button signup__content__signup__form2__add" @click="add">{{$t("header.signupPopup.add")}}</button>
            </div>
          </div>

          <div class="signup__content__signup__form3">
            <div class="signup__content__signup__form3__disclaimer">
              <input type="checkbox" name="agreement" v-model="agreement">
              {{$t("header.signupPopup.agreement1")}}
              <a :href="$t('page.extra_url.privacy_policy')" class="highlight">{{$t("header.signupPopup.agreementPrivacyPolicy")}}</a>・
              <a :href="$t('page.extra_url.disclaimer')" class="highlight">{{$t("header.signupPopup.agreementDisclaimer")}}</a>
              {{$t("header.signupPopup.agreement2")}}
            </div>
            <button class="signup__content__signup__form3__send" @click="signup" :disabled="signup_button_disabled">
              {{$t("header.signupPopup.signup")}}
            </button>
          </div>
          
          <div class="signup__content__options">
            <p>
              {{$t("header.signupPopup.loginL1")}}
              <span class="highlight" @click="gotoLogin">{{$t("header.signupPopup.login")}}</span>
            </p>
          </div>
        </div>
      </div>

      <div v-if="verify_visibled" class="signup__content__verify">
        <p>{{$t("header.signupPopup.title3")}}</p>
        <p>{{$t("header.signupPopup.sentcode")}}</p>
        <div class="signup__content__verify__area">
          <div class="signup__content__verify__form">
            <input type="text" name="code" maxlength="6" class="signup__content__verify__form__verifycode" :class="{error:isError}"
              v-model="code" :placeholder="$t('header.signupPopup.verifyCode')"/>
            <button class="signup__content__verify__form__verify" @click="verify" :disabled="!code">
              {{$t("header.signupPopup.verify")}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserinfoPopupChildItem from "@/components/global/userinfo/UserinfoPopupChildItem"
import { Auth, API } from 'aws-amplify'
import { I18n } from 'aws-amplify';

import {
  createUser,
  createChild
} from '@/graphql/mutations'

export default {
  components: {
    UserinfoPopupChildItem
  },

  // ------------------------------------ props ------------------------------------
  props: {},

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      mode: null,

      lastname: null,
      firstname: null,
      email: null,
      username: null,
      nickname: null,
      password: null,
      children: [{
        name: ""
      }],

      sent_lastname: null,
      sent_firstname: null,
      sent_email: null,
      sent_username: null,
      sent_nickname: null,
      sent_password: null,
      
      agreement: false,
      code: null,
      isError: false
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    signup_visibled() {
      return this.mode === "signup"
    },
    verify_visibled() {
      return this.mode === "verify"
    },
    signup_button_disabled() {
      return this.mode !== "signup" || this.agreement === false
          || !this.lastname || !this.firstname || !this.email
          || !this.nickname || !this.password ||
          this.validChildrenName()
    }
  },

  // ------------------------------------ watch ------------------------------------
  watch: {
    lastName: function(n){this.lastName = n.length > 10 ? n.slice(0, -1) : n},
    firstName: function(n){this.firstName = n.length > 10 ? n.slice(0, -1) : n},
    nickName: function(n){this.nickName = n.length > 15 ? n.slice(0, -1) : n},
    email: function(n){this.email = n.length > 50 ? n.slice(0, -1) : n},
    password: function(n){this.password = n.length > 50 ? n.slice(0, -1) : n},
  },


  // ------------------------------------ hook ------------------------------------
  created() {
    // モード
    this.mode = "signup"

    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("keydown", this.onKeydown, false)
  },

  beforeUnmount() {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("keydown", this.onKeydown, false)
  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    // キーダウンイベント
    onKeydown(event) {
      // Escapeボタン押下時
      if (event.key === "Escape") {
        this.closePopup()
      }
    },

    // ポップアップを閉じる
    closePopup: async function(){
      this.$emit("close-popup")
    },

    // ログインへ
    gotoLogin: async function(){
      this.$emit("goto-login")
    },

    // モード変更
    changeMode(mode) {
      this.mode = mode
    },

    // ログイン完了
    loginCompleted: async function(){
      this.$emit("login-completed")
    },

    // 追加
    add() {
      const child = {
        name: ""
      }
      this.children.push(child)
    },

    // 削除
    del(index) {
      this.children.splice(index, 1)
    },

    // 子供の名前は必須
    validChildrenName(){
      for (const child of this.children) {
        if (child.name == "") return true;
      }
      return false;
    },

    // サインアップ
    signup: async function(){
      // ユーザー名（＝アカウントIDはメアドの@を_に変更したもの）
      this.username = this.email.replace(/@/g, '_')

      this.sent_lastname = this.lastname
      this.sent_firstname = this.firstname
      this.sent_email = this.email
      this.sent_username = this.username
      this.sent_nickname = this.nickname
      this.sent_password = this.password
      
      try {
        await Auth.signUp({
          username: this.sent_username,
          password: this.sent_password,
          attributes: {
            email: this.sent_email,
          }
        });
        this.changeMode("verify")

      } catch (error) {
        const code = error.code;
        // すでに存在するアカウントID：エラーを握りつぶして認証コードを再送する
        if (code === "UsernameExistsException") {
          try {
            await Auth.resendSignUp(this.username)
            this.changeMode("verify")
            return 
          } catch (error2) {
            // 認証コード送信時にエラーが発生した場合はここに入る
            // 対象アカウントがすでに認証済みの場合もここに入る
            console.error('error signing in', error2.message);
            const err = error2.code === 'InvalidParameterException' ? this.$t('msg.signup.accountExists') : error2;
            this.$toast.error(this.$t('msg.signup.failSignup', {err : I18n.get(err.message)}));
            return
          }
        } 
        // それ以外の場合はエラー
        console.error('error signing in', error);
        this.$toast.error(this.$t('msg.signup.failSignup', {err : I18n.get(error.message)}));
        return
      }
    },

    // 認証
    verify: async function(){
      this.isError = false
      // 認証
      try {
        await Auth.confirmSignUp(
          this.sent_username,
          this.code
        );
      } catch (error) {
        console.error('error confirming sign up', error);
        this.$toast.error(this.$t('msg.signup.failVerify', {error : I18n.get(error.message)}));
        this.isError = true
        return
      }

      // ログイン
      try {
        await Auth.signIn(this.sent_username, this.sent_password);
      } catch (error) {
        console.error('error signing in', error);
        this.isError = true
        return
      }

      // 資格情報更新 ※更新に必要なので、この場で取得する
      let user_cognite_info = null
      await Auth.currentCredentials().then()
      const value = await Auth.currentUserInfo()
      user_cognite_info = value ? value.attributes : null

      // ユーザー登録
      let userId = null
      let user = {
        mailAddress: user_cognite_info.email,
        lastName: this.sent_lastname,
        firstName: this.sent_firstname,
        nickName: this.sent_nickname,
        userAlias: user_cognite_info.sub
      }
      await API.graphql(
        {
          query: createUser,
          variables: {input: user},
          authMode: "AMAZON_COGNITO_USER_POOLS"
        }
      ).then(value => {
        userId = value.data.createUser.id
      }).catch(err => {
        console.error('entry error',err)
        return
      })

      // 子供の登録
      for (const value of this.children) {
        let child = {
          userId: userId,
          name: value.name
        }
        await API.graphql(
          {
            query: createChild,
            variables: {input: child},
            authMode: "AMAZON_COGNITO_USER_POOLS"
          }
        ).catch(
          err => {
            console.error('entry error',err)
            return
          }
        )
      }
      
      // ログイン完了
      await this.loginCompleted()
      // ポップアップを閉じる
      await this.closePopup()

      this.$toast.info(this.$t('msg.login.success'));
    }
  }
}
</script>

<style scoped lang="scss">
</style>
