<template>
  <div class="mypage-view">
    <!--ナビゲーションバー-->
    <NavigationBar ref="navigationbar"  />

    <div class="container">
      <section class="explanation">
        <div class="explanation__points">
          <p>{{$t("common.404")}}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/global/navigation/NavigationBar"

export default {
  components: {
    NavigationBar,
  },

  name: "NotFound"
}
</script>

<style scoped>

</style>