<template>
  <section class="lp-comment comment">
    <p class="comment__title">{{$t("lp.comment.title")}}</p>

    <!-- 子供向け -->
    <div v-show="isChildrenMode" method="post" class="comment__form">
      <!--DBに送る、子供の反応のテキスト-->     
      <div class="comment__form__label-child">
        <label for="input-child">{{ pictureBook.basicQuestion }}</label>
      </div>
      <div class="comment__form__input-child">
        <textarea rows="3" maxlength="100" name="input-child"
          :placeholder="pictureBook.exampleBasicQuestion" v-model="commentChildrenAnswer1" @input="onInputChildrenAnswer1"/>
      </div>
      
      <!--DBに送る、親のコメントのテキスト-->
      <div class="comment__form__label-parent">
        <label for="input-parent">{{ pictureBook.freeCommentQuestion }}</label>
      </div>
      <div class="comment__form__input-parent">
        <textarea rows="3" maxlength="100" name="input-parent"
          :placeholder="pictureBook.exampleFreeComment" v-model="commentChildrenAnswer2" @input="onInputChildrenAnswer2"/>
      </div>
      <div class="comment__form__switch" @click="showGrownUp">
        <p>{{$t("lp.comment.changeParent")}}</p>
      </div>
    </div>

    <!--大人向け-->
    <div v-show="!isChildrenMode" method="post" class="comment__form">
      <!--DBに送る、大人の自分のためのメモ-->
      <div class="comment__form__label-self">
        <label for="input-self">{{ pictureBook.parentQuestion }}</label>
      </div>
      <div class="comment__form__input-self">
        <textarea rows="3" maxlength="100" name="input-self"
          :placeholder="pictureBook.exampleParentQuestion" v-model="commentGrownUpAnswer1" @input="onInputGrownUpAnswer1"/>
      </div>

      <!--DBに送る、自分のメモについてのコメントのテキスト-->
      <div class="comment__form__label-selfcom">
        <label for="input-selfcom">{{ pictureBook.parentCommentQuestion }}</label>
      </div>
      <div class="comment__form__input-selfcom">
        <textarea rows="3" maxlength="100" name="input-selfcom"
          :placeholder="pictureBook.exampleParentComment" v-model="commentGrownUpAnswer2" @input="onInputGrownUpAnswer2"/>
      </div>
      <div class="comment__form__switch" @click="showChildren">
        <p>{{$t("lp.comment.changeChild")}}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},

  // ------------------------------------ props ------------------------------------
  props: {
    isChildrenMode: {
      type: Boolean,
      require: true
    },
    pictureBook: {
      type: Object,
      require: true
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      commentChildrenAnswer1: null,
      commentChildrenAnswer2: null,
      commentGrownUpAnswer1: null,
      commentGrownUpAnswer2: null,
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {},

  // ------------------------------------ watch ------------------------------------
  watch: {},

  // ------------------------------------ methods ------------------------------------
  methods: {
    showGrownUp() {
      this.$emit("onChangeChildrenMode", false);
    },
    showChildren() {
      this.$emit("onChangeChildrenMode", true);
    },

    // 入力内容の変更
    onInputChildrenAnswer1(e) {
      this.$emit("onInputChildrenAnswer1", e.target.value);
    },
    onInputChildrenAnswer2(e) {
      this.$emit("onInputChildrenAnswer2", e.target.value);
    },
    onInputGrownUpAnswer1(e) {
      this.$emit("onInputGrownUpAnswer1", e.target.value);
    },
    onInputGrownUpAnswer2(e) {
      this.$emit("onInputGrownUpAnswer2", e.target.value);
    }
  }
}
</script>

<style scoped lang="scss">
</style>
