<template>
  <div class="navigation-bar">
    <!-- ログイン中 -->
    <NavigationBarSingin
      v-if="isSignined"
      :start_render_content="start_render_content"
      :user_cognite_info="user_cognite_info"
      :user="user"
      :children="children"
      :latestPost="latestPost"
      @logout-completed="logoutCompleted"
      @userinfo-update-completed="userinfoUpdateCompleted"
      @mail-address-update-completed="mailAddressUpdateCompleted"
    />

    <!-- ログアウト中-->
    <NavigationBarSingout
      v-else
      ref="navigationbarsingout"
      :start_render_content="start_render_content"
      @login-completed="loginCompleted"
    />
  </div>
</template>

<script>
import NavigationBarSingin from "@/components/global/navigation/NavigationBarSingin"
import NavigationBarSingout from "@/components/global/navigation/NavigationBarSingout"

export default {
  components: {
    NavigationBarSingin,
    NavigationBarSingout
  },

  // ------------------------------------ props ------------------------------------
  props: {
    // ユーザー情報確認済みか？（した後で読み込み）
    start_render_content: {
      type: Boolean,
      require: true
    },
    // ユーザー情報（cognito）
    user_cognite_info: {
      type: Object,
      require: true
    },
    // ユーザー（DB）
    user: {
      type: Object,
      require: true
    },
    // 子供（DB）
    children: {
      type: Array,
      require: true
    },
    // 最新投稿（DB）
    latestPost: {
      type: Object,
      require: true
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {}
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    // サインイン状態
    isSignined() {
      return !!(this.user_cognite_info)
    }
  },
  mounted() {},
  // ------------------------------------ methods ------------------------------------
  methods: {
    // ログインポップアップ起動
    openLoginPopup() {
      this.$refs["navigationbarsingout"].openLoginPopup()
    },

    // サインアップポップアップ起動
    openSignupPopup() {
      this.$refs["navigationbarsingout"].openSignupPopup()
    },

    // ログイン完了
    loginCompleted() {
      this.$emit("login-completed")
    },
    // ログアウト完了
    logoutCompleted() {
      this.$emit("logout-completed")
    },
    // ユーザー情報更新完了
    userinfoUpdateCompleted: async function(){
      this.$emit("userinfo-update-completed")
    },
    // メールアドレス更新完了
    mailAddressUpdateCompleted: async function(){
      this.$emit("mail-address-update-completed")
    },
  }
}
</script>

<style scoped lang="scss">
</style>
