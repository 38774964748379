import { createRouter, createWebHistory } from 'vue-router'
import DummyView from '@/views/DummyView.vue'
import PictureBookView from '@/views/PictureBookView.vue'
import MypageView from '@/views/MypageView.vue'
import EditPictureBook from '@/views/EditPictureBook.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MypageView,
    meta: {
      title: 'page.mypage.title',
      desc: 'page.mypage.description'
    }
  },
  {
    path: '/mypage/',
    name: 'MypageView',
    component: MypageView,
    meta: {
      title: 'page.mypage.title',
      desc: 'page.mypage.description'
    }
  },
  {
    path: '/dummy',
    name: 'DummyView',
    component: DummyView,
    meta: {
      title: 'page.dummy.title',
      desc: 'page.dummy.description'
    }
  },
  {
    path: '/admin/pictureBook',
    name: 'editPictureBook',
    component: EditPictureBook,
    meta: {
      title: 'page.editPictureBook.title',
      desc: 'page.editPictureBook.description'
    }
  },
  {
    path: '/pb/:questionCode/',
    name: 'PictureBookLpView',
    component: PictureBookView,
    // 絵本取得後に決まるので、titleとdescは設定しない
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFound,
    meta: {
      title: 'NotFound',
      desc: 'NotFound',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
