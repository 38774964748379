/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPictureBook = /* GraphQL */ `
  mutation CreatePictureBook(
    $input: CreatePictureBookInput!
    $condition: ModelPictureBookConditionInput
  ) {
    createPictureBook(input: $input, condition: $condition) {
      id
      questionCode
      name
      picturePath
      basicQuestion
      parentQuestion
      freeCommentQuestion
      parentCommentQuestion
      exampleBasicQuestion
      exampleFreeComment
      exampleParentQuestion
      exampleParentComment
      createdAt
      updatedAt
    }
  }
`;
export const updatePictureBook = /* GraphQL */ `
  mutation UpdatePictureBook(
    $input: UpdatePictureBookInput!
    $condition: ModelPictureBookConditionInput
  ) {
    updatePictureBook(input: $input, condition: $condition) {
      id
      questionCode
      name
      picturePath
      basicQuestion
      parentQuestion
      freeCommentQuestion
      parentCommentQuestion
      exampleBasicQuestion
      exampleFreeComment
      exampleParentQuestion
      exampleParentComment
      createdAt
      updatedAt
    }
  }
`;
export const deletePictureBook = /* GraphQL */ `
  mutation DeletePictureBook(
    $input: DeletePictureBookInput!
    $condition: ModelPictureBookConditionInput
  ) {
    deletePictureBook(input: $input, condition: $condition) {
      id
      questionCode
      name
      picturePath
      basicQuestion
      parentQuestion
      freeCommentQuestion
      parentCommentQuestion
      exampleBasicQuestion
      exampleFreeComment
      exampleParentQuestion
      exampleParentComment
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      mailAddress
      lastName
      firstName
      nickName
      userAlias
      children {
        items {
          id
          userId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          postedAt
          userId
          childId
          contentType
          pictureBookId
          answerForBasicQuestion
          answerFreeComment
          answerParentQuestion
          answerParentComment
          emotionalPoint
          emotionalType
          optionContent
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      mailAddress
      lastName
      firstName
      nickName
      userAlias
      children {
        items {
          id
          userId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          postedAt
          userId
          childId
          contentType
          pictureBookId
          answerForBasicQuestion
          answerFreeComment
          answerParentQuestion
          answerParentComment
          emotionalPoint
          emotionalType
          optionContent
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      mailAddress
      lastName
      firstName
      nickName
      userAlias
      children {
        items {
          id
          userId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          postedAt
          userId
          childId
          contentType
          pictureBookId
          answerForBasicQuestion
          answerFreeComment
          answerParentQuestion
          answerParentComment
          emotionalPoint
          emotionalType
          optionContent
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createChild = /* GraphQL */ `
  mutation CreateChild(
    $input: CreateChildInput!
    $condition: ModelChildConditionInput
  ) {
    createChild(input: $input, condition: $condition) {
      id
      userId
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateChild = /* GraphQL */ `
  mutation UpdateChild(
    $input: UpdateChildInput!
    $condition: ModelChildConditionInput
  ) {
    updateChild(input: $input, condition: $condition) {
      id
      userId
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteChild = /* GraphQL */ `
  mutation DeleteChild(
    $input: DeleteChildInput!
    $condition: ModelChildConditionInput
  ) {
    deleteChild(input: $input, condition: $condition) {
      id
      userId
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      postedAt
      userId
      childId
      child {
        id
        userId
        name
        createdAt
        updatedAt
        owner
      }
      contentType
      pictureBookId
      answerForBasicQuestion
      answerFreeComment
      answerParentQuestion
      answerParentComment
      emotionalPoint
      emotionalType
      optionContent
      createdAt
      updatedAt
      pictureBook {
        id
        questionCode
        name
        picturePath
        basicQuestion
        parentQuestion
        freeCommentQuestion
        parentCommentQuestion
        exampleBasicQuestion
        exampleFreeComment
        exampleParentQuestion
        exampleParentComment
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      postedAt
      userId
      childId
      child {
        id
        userId
        name
        createdAt
        updatedAt
        owner
      }
      contentType
      pictureBookId
      answerForBasicQuestion
      answerFreeComment
      answerParentQuestion
      answerParentComment
      emotionalPoint
      emotionalType
      optionContent
      createdAt
      updatedAt
      pictureBook {
        id
        questionCode
        name
        picturePath
        basicQuestion
        parentQuestion
        freeCommentQuestion
        parentCommentQuestion
        exampleBasicQuestion
        exampleFreeComment
        exampleParentQuestion
        exampleParentComment
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      postedAt
      userId
      childId
      child {
        id
        userId
        name
        createdAt
        updatedAt
        owner
      }
      contentType
      pictureBookId
      answerForBasicQuestion
      answerFreeComment
      answerParentQuestion
      answerParentComment
      emotionalPoint
      emotionalType
      optionContent
      createdAt
      updatedAt
      pictureBook {
        id
        questionCode
        name
        picturePath
        basicQuestion
        parentQuestion
        freeCommentQuestion
        parentCommentQuestion
        exampleBasicQuestion
        exampleFreeComment
        exampleParentQuestion
        exampleParentComment
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
