import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import i18n from './i18n';
import Toaster from "@meforma/vue-toaster";

import '@/assets/sass/main.scss';

import {
    applyPolyfills,
    defineCustomElements
} from '@aws-amplify/ui-components/loader';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

applyPolyfills().then(() => {
    defineCustomElements(window);
});

const app =  createApp(App);

app.config.isCustomElement = tag => tag.startsWith('amplify-');

app.use(router);
app.use(i18n);
app.use(Toaster,{
    // One of the options
    position: "top",
})

app.mount('#app');
