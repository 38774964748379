// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ContentType = {
  "PICTURE_BOOK": "PICTURE_BOOK"
};

const EmotionalType = {
  "LIKE": "LIKE",
  "NOTLIKE": "NOTLIKE",
  "LOVE": "LOVE",
  "HATE": "HATE",
  "HAPPY": "HAPPY",
  "VERYHAPPY": "VERYHAPPY",
  "ENJOYING": "ENJOYING",
  "EXCITED": "EXCITED",
  "SAD": "SAD",
  "VERYSAD": "VERYSAD",
  "FRUSTRATED": "FRUSTRATED",
  "ANGRY": "ANGRY",
  "WARM": "WARM",
  "BROKEN": "BROKEN",
  "INSPIRED": "INSPIRED",
  "WONDERING": "WONDERING",
  "CONFUSED": "CONFUSED",
  "INTERESTED": "INTERESTED",
  "SURPRISED": "SURPRISED",
  "BORED": "BORED",
  "FIREDUP": "FIREDUP",
  "BUSY": "BUSY",
  "TIRED": "TIRED",
  "DEPRESSED": "DEPRESSED",
  "COURAGE": "COURAGE",
  "FINE": "FINE",
  "WORRIED": "WORRIED",
  "PANIC": "PANIC",
  "SUCCESS": "SUCCESS",
  "MADEIT": "MADEIT",
  "MISSED": "MISSED",
  "FAIL": "FAIL",
  "PROUD": "PROUD",
  "SHY": "SHY",
  "JEALOUS": "JEALOUS",
  "ENVIOUS": "ENVIOUS",
  "NEUTRAL": "NEUTRAL"
};

const { PictureBook, User, Child, Post } = initSchema(schema);

export {
  PictureBook,
  User,
  Child,
  Post,
  ContentType,
  EmotionalType
};