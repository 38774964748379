<template>
  <div class="navigation-bar-signin">
    <nav class="nav">
      <figure class="nav__image nav__image--1">
        <a :href="$t('page.extra_url.index')" class="toppage">
          <img class="nav__logo" src="@/assets/img/toybox-logo.png" alt="toybox logo" />
        </a>
      </figure>
      <figure class="nav__image nav__image--2">
        <img class="nav__title" src="@/assets/img/titlelogo.svg" alt="title logo" />
      </figure>
      <div v-if="start_render_content" class="nav__user">
        <!-- マイページ -->
        <router-link v-if="latestPost" :to="latestLpUrl"><img class="nav__user__icon" src="@/assets/img/memo.svg"/></router-link>
        <router-link to="/mypage"><img class="nav__user__icon" src="@/assets/img/home.svg"/></router-link>

        <!-- メニュー -->
<!--        <p class="nav__user__logout"  @click="changeMenuState">-->
<!--          メニュー-->
<!--        </p>-->
        <img class="nav__user__icon" src="@/assets/img/menudots.svg" @click="changeMenuState"/>

        <nav class="nav__menu" v-bind:class='{active:isMenuActive}'>
          <ul>
            <li><a @click="openUserinfoPopup">{{$t("header.userInfo")}}</a></li>
            <li><a @click="openMailAddressPopup">{{$t("header.mailAddressUpdate")}}</a></li>
            <li><a @click="logout">{{$t("header.logout")}}</a></li>
          </ul>
        </nav>
      </div>
    </nav>

    <!-- ユーザー情報ポップアップ -->
    <UserinfoPopup
      v-if="isUserinfoPopupVislble"
      :user_cognite_info="user_cognite_info"
      :user="user"
      :children="children"
      @userinfo-update-completed="userinfoUpdateCompleted"
      @close-popup="closeUserinfoPopup"
    />
    <!-- メールアドレスポップアップ -->
    <MailAddressPopup
      v-if="isMailAddressPopupVislble"
      :user="user"
      @mail-address-update-completed="mailAddressUpdateCompleted"
      @close-popup="closeMailAddressPopup"
    />
  </div>
</template>

<script>
import UserinfoPopup from "@/components/global/userinfo/UserinfoPopup"
import MailAddressPopup from "@/components/global/mailAddress/MailAddressPopup"
import { Auth } from 'aws-amplify'

export default {
  components: {
    UserinfoPopup,
    MailAddressPopup
  },
  // ------------------------------------ props ------------------------------------
  props: {
    // ユーザー情報確認済みか？（した後で読み込み）
    start_render_content: {
      type: Boolean,
      require: true
    },
    // ユーザー情報（cognito）
    user_cognite_info: {
      type: Object,
      require: true
    },
    // ユーザー（DB）
    user: {
      type: Object,
      require: true
    },
    // 子供（DB）
    children: {
      type: Array,
      require: true
    },
    // 最新投稿（DB）
    latestPost: {
      type: Object,
      require: true
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      // 各ポップアップの表示制御
      isUserinfoPopupVislble: false,
      isMailAddressPopupVislble: false,
      // メニュー表示
      isMenuActive: false
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    latestLpUrl() {
      return `/pb/${this.latestPost.pictureBook.questionCode}/`
    },

    isUserinfoPopupButtonVislble() {
      return !!(this.user)
    }
  },

  // ------------------------------------ watch ------------------------------------
  watch: {},

  // ------------------------------------ methods ------------------------------------
  methods: {
    // メニュー表示、非表示の切り替え
    changeMenuState() {
      this.isMenuActive = !this.isMenuActive
    },
    // メニュー非表示
    closeMenu() {
      this.isMenuActive = false
    },
    // ユーザー情報ポップアップの開閉
    openUserinfoPopup() {
      this.closeMenu()
      this.isUserinfoPopupVislble = true
      this.isMailAddressPopupVislble = false
    },
    closeUserinfoPopup() {
      this.isUserinfoPopupVislble = false
    },
    // メールアドレスポップアップの開閉
    openMailAddressPopup() {
      this.closeMenu()
      this.isMailAddressPopupVislble = true
      this.isUserinfoPopupVislble = false
    },
    closeMailAddressPopup() {
      this.isMailAddressPopupVislble = false
    },

    // ユーザー情報更新完了
    userinfoUpdateCompleted: async function(){
      this.$emit("userinfo-update-completed")
    },

    // メールアドレス更新完了
    mailAddressUpdateCompleted: async function(){
      this.$emit("mail-address-update-completed")
    },

    // ログアウト
    logout: async function(){
      try{
        await Auth.signOut()
        this.$emit("logout-completed")
      } catch(error) {
        console.error('logout', error)
        return
      }
      this.closeMenu()
      // リロード
      this.$router.go({path: this.$router.currentRoute.path, force: true})
      this.$toast.info(this.$t('msg.logout.success'));
    }
  }
}
</script>

<style scoped lang="scss">
nav.nav__menu {
  display: none;
  position: absolute;
  z-index : 2;
  top  : 3.25em;
  right: 0.75em;
  color: #fff;
  background: #ffad15;
  filter: drop-shadow(0 0 2px #b3b3b3);
  text-align: center;
  width: 12.5em;
}
nav.nav__menu ul {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
nav.nav__menu ul li {
  list-style-type: none;
  padding: 0;
  width: 100%;
}
nav.nav__menu ul li:last-child {
  padding-bottom: 0;
}
nav.nav__menu ul li:hover{
  background :#ddd;
}
nav.nav__menu ul li a {
  display: block;
  color: #fff;
  padding: 1em 0;
  text-decoration :none;
}
nav.nav__menu.active {
  display: block;
}
</style>
