export default {
  page: {
    mypage: {
      title: "My Page",
      description: "My Page",
    },
    dummy: {
      title: "Dummy",
      description: "Dummy",
    },
    editPictureBook: {
      title: "Edit picture book",
      description: "Edit picture book",
    },
    extra_url: {
      index: "https://www.toybox.fun/",
      pictureBook: "https://toybox.fun/picturebook/",
      disclaimer: "https://toybox.fun/disclaimer-and-copyrights/",
      privacy_policy: "https://toybox.fun/privacypolicy/",
    },
  },
  title: {
    lp: "LP",
    myPage: "Mypage",
    editPictureBook: "Edit page",
  },
  common: {
    404: "404 Can not find the page.",
  },
  header: {
    userInfo: "Profile",
    mailAddressUpdate: "Update email",
    logout: "Log out",
    login: "Log in",
    signup: "Sign up",
    signupPopup: {
      title: "Register to Likelog",
      lastname: "Last name",
      firstname: "First name",
      email: "email address",
      nickname: "User name（Nick name）",
      password: "Password (over 8 characters)",
      title2: "Your child's name",
      add: "Add",
      agreement1: "",
      agreementPrivacyPolicy: "Privacy policy",
      agreementDisclaimer: "Disclaimer and copyrights",
      agreement2: "are agreed.",
      signup: "Sign up",
      loginL1: "If already sighned up:",
      login: "Login",
      title3: "Input verification code",
      verifyCode: "Verification code",
      verify: "Verify",
      sentcode: "Verification code is sent. Please check your mailbox.",
    },
    loginPopup: {
      title: "Login to Likelog",
      email: "email address",
      password: "password",
      login: "Login",
      resetL1: "If you forgot the password:",
      reset: "Reset",
      signupL1: "If you haven't signed up yet:",
      signup: "Sign up",
    },
    passwordResetPopup: {
      title1: "Reset password",
      caption1_1: "Please enter your email address",
      email: "email address",
      sendResetCode: "Send reset code",
      login: "Back to login",

      title2: "Reset password",
      caption2_1: "Check you email and input the reset code and new password",
      resetCode: "Reset code",
      newPassword: "New password",
      reset: "Reset",
    },
    userinfoPopup: {
      title1: "Update profile",
      lastname: "Last name",
      firstname: "First name",
      nickname: "User name (Nick name)",
      title2: "Update your child's name",
      add: "Add",
      update: "Update",
      childname: "Child's name",
      delete: "Delete",
    },
    mailAddressPopup: {
      title1: "Please input",
      caption1_1: "new email address",
      email: "email address",
      sendVerifyCode: "Send verification code",
      login: "Back to login",
      title2: "Input verification code",
      caption2_1: "Please check your mail and input verification code",
      verifyCode: "Verification code",
      update: "Update",
    },
  },
  footer: {
    topPage: "©toybox picture book",
    disclaimerCopyright: "Disclaimer and copyrights",
    privacyPolicy: "Privacy policy",
  },
  lp: {
    explanation: "Note your heart reading the book",
    emotionTitle: "How did you feel?",
    upliftMeterTitle: "How uplifting?",
    upliftZero: "No change",
    submitToMyPage: "Save to mypage",
    signup: "Sign up",
    // login: "登録済みの方はこちら",
    login: "Login",
    popup: {
      title: "Select basic emoticon",
      detail: "Select detail emoticon",
      like: "Liked?",
      happy: "Happy?",
      enjoying: "Enjoying?",
      heart: "Heartwarming?",
      think: "Thoughs?",
      new: "Surprised?",
      spirit: "Motivated?",
      ready: "Ready?",
      result: "Result?",
      self: "Proud?",
      neutral: "Normal?",
    },

    comment: {
      title: "Questions",
      parent: "Comment by parents",
      changeParent: "For grown ups",
      free: "Free comments",
      changeChild: "For children",
      // insteadTitle2: "代理で記録",
      insteadTitle2: "Who's record?",
      representative: "For {name}",
    },
  },
  manual: {
    title: "How to use the app",
    step1: {
      title: "1. Note the experience",
      l1:
        "Ask questions after reading the book. Note what your child responded.",
      l2:
        "Select the closest emotion and the level of ups and downs of excitement.",
      l3: "Press 'save to mypage'.",
    },
    step2: {
      title: "2. Save to mypage",
      l1:
        "In mypage, noted responses will be saved along with the pictures from the book.",
      l2: "You can post as many posts as you wish.",
    },
    step3: {
      title: "3. Share",
      l1: "You can post at twitter and facebook with a single click.",
      l2:
        "You can also download the post and freely post it at your favorite SNS.  Adding #toybox is appreciated.",
    },
  },
  mypage: {
    representative: "Note for {name}",
    download: "Download post",
    delete_post: "Delete post",
    delete_post_confirm: "Do you want to delete the post?",
    isPostNotExisted: "There are no posts",
    emotionalPoint: "Excitement",
  },
  share: {
    post: "#toybox\n",
  },
  msg: {
    signup: {
      failSignup: "Failed to sign up{err}",
      failVerify: "Failed to verify sign up{err}",
      accountExists: "The account already exists",
    },
    login: {
      success: "Login complete",
      fail: "Failed to login",
    },
    logout: {
      success: "Logout complete",
    },
    passwordReset: {
      success: "Password reset complete",
      failSendResetCode: "Failed to send reset code",
      failResetPassword: "Failed to reset passoword",
    },
    userinfo: {
      updated: "Profile update complete",
    },
    mailAddress: {
      notChanged: "「{mail}」is the current email address",
      success: "email update complete{mail}",
      failSendVerifyCode: "Failed to send verification code",
      failUpdateMailAddress: "Failed to update email",
    },
    lp: {
      posted: "Post complete",
      selectChild: "Select your child",
    },
    mypage: {
      post_deleted: "Post deleted",
    },
  },
};
