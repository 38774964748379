<template>
  <div class="userinfo-popup userinfo">
    <!-- ユーザー情報更新 -->
    <div class="userinfo__content">
      <a class="userinfo__content__close" @click="closePopup">&times;</a>
      <p>{{$t("header.userinfoPopup.title1")}}</p>
      <div class="userinfo__content__form">
        <input type="text" name="lastname" maxlength="15" class="userinfo__content__form__lastname" v-model="lastName" :placeholder="$t('header.userinfoPopup.lastname')">
        <input type="text" name="firstname" maxlength="15" class="userinfo__content__form__firstname" v-model="firstName" :placeholder="$t('header.userinfoPopup.firstname')">
        <input type="text" name="username" maxlength="15" class="userinfo__content__form__username" v-model="nickName" :placeholder="$t('header.userinfoPopup.nickname')">
      </div>

      <p>{{$t("header.userinfoPopup.title2")}}</p>
      <div class="userinfo__content__form2">
        <div v-for="(child, index) in afterChildren" :key="index">
          <UserinfoPopupChildItem
            :child="child"
            :index="index"
            @onInputText="child.name = $event"
            @del="del(index)"
          />
        </div>
        <div class="userinfo__content__form2__add" v-show="afterChildren.length <=5">
          <button class="userinfo__content__button userinfo__content__form2__add-area" @click="add">
            <img class="userinfo__content__form2__add-icon" src="@/assets/img/plus.svg"/>
          </button>
        </div>
      </div>
      <button class="userinfo__content__button userinfo__content__form2__update" @click="update" :disabled="!lastName || !firstName || !nickName|| this.validChildrenName()">{{$t("header.userinfoPopup.update")}}</button>    
    </div>
  </div>
</template>

<script>
import UserinfoPopupChildItem from "@/components/global/userinfo/UserinfoPopupChildItem"

import { API } from 'aws-amplify'
import {
  updateUser,
  createChild,
  updateChild,
  deleteChild
} from '@/graphql/mutations'

export default {
  components: {
    UserinfoPopupChildItem
  },

  // ------------------------------------ props ------------------------------------
  props: {
    // ユーザー情報（cognito）
    user_cognite_info: {
      type: Object,
      require: true
    },
    // ユーザー（DB）
    user: {
      type: Object,
      require: true
    },
    // 子供（DB）
    children: {
      type: Array,
      require: true
    }
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      lastName: null,
      firstName: null,
      nickName: null,
      afterChildren: [],
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {},

  // ------------------------------------ watch ------------------------------------
  watch: {
    lastName: function(n){this.lastName = n.length > 10 ? n.slice(0, -1) : n},
    firstName: function(n){this.firstName = n.length > 10 ? n.slice(0, -1) : n},
    nickName: function(n){this.nickName = n.length > 15 ? n.slice(0, -1) : n},
  },


  // ------------------------------------ hook ------------------------------------
  created() {
    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("keydown", this.onKeydown, false)
  },

  mounted() {
    // 初期処理
    this.initialize()
  },


  beforeUnmount() {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("keydown", this.onKeydown, false)
  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    // 初期処理
    initialize: async function(){
      this.lastName = this.user.lastName
      this.firstName = this.user.firstName
      this.nickName = this.user.nickName
      for (const child of this.children) { 
        this.afterChildren.push(JSON.parse(JSON.stringify(child)))
      }
    },

    // キーダウンイベント
    onKeydown(event) {
      // Escapeボタン押下時
      if (event.key === "Escape") {
        this.closePopup()
      }
    },

    // 更新完了
    userinfoUpdateCompleted: async function(){
      this.$emit("userinfo-update-completed")
    },

    // ポップアップを閉じる
    closePopup: async function(){
      this.$emit("close-popup")
    },

    // メールアドレス更新へ
    gotoMailAddress: async function(){
      this.$emit("goto-mailaddress")
    },

    // 追加
    add() {
      const child = {
        userId: this.user.id,
        name: ""
      }
      this.afterChildren.push(child)
    },

    // 削除
    del(index) {
      this.afterChildren.splice(index, 1)
    },

    // 子供の名前は必須
    validChildrenName(){
      for (const child of this.afterChildren) {
        if (child.name == "") return true;
      }
      return false;
    },

    // 更新
    update: async function(){
      try{
        // ユーザーの更新
        await this.updateUser()
        // 子供の更新
        await this.updateChild()
      } catch (error) {
        console.error('entry error', error)
        return
      }
      this.userinfoUpdateCompleted()
      this.closePopup()

      this.$toast.info(this.$t('msg.userinfo.updated'));
    },

    // ユーザーの更新
    updateUser: async function(){
      // ユーザーの更新
      let user = {
        id: this.user.id,
        lastName: this.lastName,
        firstName: this.firstName,
        nickName: this.nickName,
      }
      await API.graphql(
        {
          query: updateUser,
          variables: {input: user},
          authMode: "AMAZON_COGNITO_USER_POOLS"
        }
      )
    },

    // 子供の更新
    updateChild: async function(){
      // 子供の変更状態を確認し、処理を決定
      const saveChildren = []
      const updateChildren = []
      const deleteChildren = []

      // 変更前の子供をループ
      for (const child of this.children) {
        let isDelete = true
        // 変更後の子供をループ        
        for (const afterChild of this.afterChildren) {
          // IDが同じものが存在
          if (child.id === afterChild.id) {
            // 名前が変更されていたら更新対象
            if (child.name !== afterChild.name) {
              updateChildren.push(JSON.parse(JSON.stringify(afterChild)))
            }
            // 対象のafterChildは登録対象ではない
            afterChild.isNOTSave = true
            // 対象のchildは削除対象ではない
            isDelete = false
            break
          }
        }

        // afterChildに存在しない場合は削除対象
        if (isDelete) {
          deleteChildren.push(JSON.parse(JSON.stringify(child)))
        }
      }
      // 最後まで残ったafterChildは登録対象
      for (const afterChild of this.afterChildren) {
        if (afterChild.isNOTSave !== true) {
          saveChildren.push(JSON.parse(JSON.stringify(afterChild)))
        }
      }
      
      // 子供の登録：saveChildren
      for (const child of saveChildren) {
        await API.graphql(
          {
            query: createChild,
            variables: {input: child},
            authMode: "AMAZON_COGNITO_USER_POOLS"
          }
        )
      }

      // 子供の更新：updateChildren
      for (const child of updateChildren) {
        await API.graphql(
          {
            query: updateChild,
            variables: {input: {
              id: child.id,
              name: child.name
            }},
            authMode: "AMAZON_COGNITO_USER_POOLS"
          }
        )
      }

      // 子供の削除：deleteChildren
      for (const child of deleteChildren) {
        const idObj = {id: child.id}
        await API.graphql({
          query: deleteChild,
          variables: {input: idObj},
          authMode: "AMAZON_COGNITO_USER_POOLS"
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
