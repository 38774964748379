<template>
  <main class="mypage-post-item mainpost">
    <!-- 投稿 -->
    <div class="mainpost__question">
      <template v-if="isChildPost">
        {{ $t("mypage.representative", { name: post.child.name }) }}<br />
      </template>
    </div>
    <div class="mainpost__content">
      <Canvas
        ref="canvas"
        class="mainpost__content__img"
        :src="dispInfo.picturePath"
        :answer="dispInfo.answer"
        :question="dispInfo.question"
      />
    </div>

    <div class="mainpost__mood">
      <img :src="emotionIconPath" class="mainpost__mood__emoticon" />
      <div class="mainpost__mood__moodmeter">{{ dispEmotionalPoint }}</div>
    </div>

    <p class="mainpost__comment">
      {{ dispInfo.comment }}
    </p>

    <div class="mainpost__timestamp">
      <p v-if="dispInfo.postedAt">
        {{ $d(new Date(dispInfo.postedAt), "long") }}
        <u
          ><a @click="delete_post">{{ $t("mypage.delete_post") }}</a></u
        >
      </p>
    </div>

    <div class="mainpost__sns">
      <img
        @click="shareTwitter"
        src="@/assets/img/twitter.png"
        class="mainpost__sns__twitter"
        alt="twitter"
      />
      <img
        @click="shareFB"
        src="@/assets/img/facebook.png"
        class="mainpost__sns__facebook"
        alt="facebook"
      />
      <img
        @click="imageDownload"
        src="@/assets/img/download.png"
        class="mainpost__sns__download"
        alt="{{$t('mypage.download')}}"
      />
    </div>
  </main>
</template>

<script>
import Canvas from "@/components/molecule/Canvas";
import { EmotionalType } from "@/models/index";

import { Storage } from "aws-amplify";
import awsconfig from "../../../aws-exports";

// import shareFacebook from "share-facebook"

export default {
  components: {
    Canvas,
  },

  // ------------------------------------ props ------------------------------------
  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      filename: "aaa",
    };
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    isChildPost() {
      return !!this.post.childId;
    },

    dispInfo() {
      // null回避
      if (!this.post.pictureBook) {
        return {
          question: "",
          answer: "",
          freeQuestion:"",
          comment: "",
          contentName: "",
          picturePath: "",
          postedAt: "",
        };
      }
      // 子供向け投稿
      if (this.isChildPost) {
        return {
          question: this.post.pictureBook.basicQuestion,
          answer: this.post.answerForBasicQuestion,
          freeQuestion:this.post.pictureBook.freeCommentQuestion,
          comment: this.post.answerFreeComment,
          contentName: this.post.pictureBook.name,
          picturePath: this.post.pictureBook.picturePath,
          postedAt: this.post.postedAt,
        };
      } else {
        // 大人向け投稿
        return {
          question: this.post.pictureBook.parentQuestion,
          answer: this.post.answerParentQuestion,
          freeQuestion:this.post.pictureBook.parentCommentQuestion,
          comment: this.post.answerParentComment,
          contentName: this.post.pictureBook.name,
          picturePath: this.post.pictureBook.picturePath,
          postedAt: this.post.postedAt,
        };
      }
    },

    dispEmotionalPoint() {
      const sign = this.post.emotionalPoint > 0 ? "+" : "";
      return this.$t("mypage.emotionalPoint") + " " + sign + this.post.emotionalPoint;
    },

    emotionIconPath() {
      return require("@/assets/img/emoji/" +
        EmotionalType[this.post.emotionalType] +
        ".png");
    },

    isAvailableWebShareAPI() {
      return navigator.share;
    },
  },

  // ------------------------------------ watch ------------------------------------
  watch: {},

  // ------------------------------------ methods ------------------------------------
  methods: {
    // シェア
    share() {
      return this.$refs.canvas.toBlob();
    },

    async resizeImage(base64, callback) {
      const MIN_SIZE = 400;
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function() {
        let dstWidth, dstHeight;
        if (this.width > this.height) {
          dstWidth = MIN_SIZE;
          dstHeight = (this.height * MIN_SIZE) / this.width;
        } else {
          dstHeight = MIN_SIZE;
          dstWidth = (this.width * MIN_SIZE) / this.height;
        }
        canvas.width = dstWidth;
        canvas.height = dstHeight;
        ctx.drawImage(
          this,
          0,
          0,
          this.width,
          this.height,
          0,
          0,
          dstWidth,
          dstHeight
        );
        callback(canvas.toDataURL());
      };
      image.src = base64;
    },

    base64toBlob(base64) {
      const bin = atob(base64.replace(/^.*,/, ""));
      const buffer = new Uint8Array(bin.length);
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      try {
        return new Blob([buffer.buffer], {
          type: "image/jpeg",
        });
      } catch (e) {
        return false;
      }
    },

    imageDownload() {
      const cv = this.$refs.canvas.$el;
      const base64 = cv.toDataURL();
      this.resizeImage(base64, (base64) => {
        const blob = this.base64toBlob(base64);
        const shareImg = new File([blob], "shareImage.png", {
          type: "image/png",
        });
        const dataURI = window.URL.createObjectURL(shareImg); // Blobデータを「URI」に変換
        const event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click",
          true,
          false,
          window,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null
        );
        const a = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        a.href = dataURI; // URI化した画像
        a.download = `toybox-${this.dispInfo.postedAt}.jpg`; // デフォルトのファイル名
        a.dispatchEvent(event); // イベント発動
      });
    },

    async putImage() {
      const cv = this.$refs.canvas.$el;
      const imgFileName = "user/share/" + this.post.id + ".jpg";
      const htmlFileName = "user/share/" + this.post.id + ".html";
      this.filename = imgFileName;
      const bucket = awsconfig.aws_user_files_s3_bucket;

      const base64 = cv.toDataURL();
      const image_path = `https://${bucket}.s3.amazonaws.com/public/${imgFileName}`;

      const html = `<!DOCTYPE html>
                <html prefix="og: http://ogp.me/ns# website: https://ogp.me/ns/website#">
                <head>
                  <!-- 共通項目 -->
                  <meta property="og:locale" content="ja_JP">
                  <meta property="og:title" content="${this.dispInfo.contentName}" />
                  <meta property="og:type" content="website" />
                  <!-- <meta property="og:url" content="https://hogehoge/" />-->
                  <meta property="og:image" content="${image_path}" />
                  <meta property="og:site_name"  content="toybox" />
                  <meta property="og:description" content="${this.dispInfo.comment ? this.dispInfo.comment : '　'}" />

                  <!-- Twitter -->
                  <meta name="twitter:title" content="${this.dispInfo.contentName}" />
                  <meta name="twitter:card" content="summary_large_image"/>
                  <meta name="twitter:site" content="@toybox" />
                  <meta name="twitter:creator" content="@toybox"/>
                  <meta name="twitter:image" content="${image_path}" />

                  <style media="(max-width:1000px)">
                    img{
                        width: 100%;
                    }
                  </style>
                  <style media="(min-width:1001px)">
                    img{
                        width: 800px;
                    }
                  </style>
                </head>
                <body>
                  <img src="${image_path}">
                </body>
                </html>`;

      await this.resizeImage(base64, async (base64) => {
        const blob = this.base64toBlob(base64);
        await Storage.put(imgFileName, blob, {
          contentType: "image/jpeg", // contentType is optional
        });
        await Storage.put(htmlFileName, html, {
          contentType: "text/html", // contentType is optional
        });
      });
      const s3path = `https://${bucket}.s3.amazonaws.com/public/${htmlFileName}`;
      return s3path;
    },

    async shareTwitter() {
      const s3path = await this.putImage();
      const twHref =
        "https://twitter.com/share?text=" +
        encodeURIComponent(this.$i18n.t("share.post")) +
        "&url=" +
        encodeURIComponent(s3path);
      window.open(
        twHref,
        "",
        "width=580,height=400,menubar=no,toolbar=no,scrollbars=yes"
      );
    },

    async shareFB(){
      const s3path = await this.putImage()
      const fbHref = 'http://www.facebook.com/share.php?u='+encodeURIComponent(s3path);
      window.open(fbHref, '', 'width=580,height=400,menubar=no,toolbar=no,scrollbars=yes');
    },

    delete_post() {
      this.$emit("delete_post");
    },
  },
};
</script>

<style scoped lang="scss"></style>
