<template>
  <div class="passwordreset-popup passwordreset">
    <!-- サインアップ -->
    <div class="passwordreset__content">
      <a class="passwordreset__content__close" @click="closePopup">&times;</a>

      <div v-if="passwordreset_visibled" class="passwordreset__content__passwordreset">
        <p>{{$t("header.passwordResetPopup.title1")}}</p>
        <p>{{$t("header.passwordResetPopup.caption1_1")}}</p>
        <div class="passwordreset__content__passwordreset__area">
          <div class="passwordreset__content__passwordreset__form1">
            <input type="email" name="email" class="passwordreset__content__passwordreset__form1__email" :class="{error:isError}"
              v-model="email" :placeholder="$t('header.passwordResetPopup.email')"/>
            <button class="passwordreset__content__passwordreset__form1__reset" @click="sendResetCode" :disabled="!email">
              {{$t("header.passwordResetPopup.sendResetCode")}}
            </button>
          </div>
        </div>
        <div class="passwordreset__content__options">
          <p>
            <span class="highlight" @click="gotoLogin">{{$t("header.passwordResetPopup.login")}}</span>
          </p>
        </div>
      </div>

      <div v-if="verify_visibled" class="passwordreset__content__verify">
        <p>{{$t("header.passwordResetPopup.title2")}}</p>
        <p>{{$t("header.passwordResetPopup.caption2_1")}}</p>
        <div class="passwordreset__content__verify__area">
          <div class="passwordreset__content__verify__form">
            <input type="text" name="code" maxlength="6" class="passwordreset__content__verify__form__verifycode" :class="{error:isError}"
              v-model="code" :placeholder="$t('header.passwordResetPopup.resetCode')"/>
            <input type="password" name="new_password" maxlength="50" class="passwordreset__content__verify__form__password" :class="{error:isError}"
              v-model="new_password" :placeholder="$t('header.passwordResetPopup.newPassword')">
            <button class="passwordreset__content__verify__form__verify" @click="verifyAndReset" :disabled="verify_disabled || !code || !new_password">
              {{$t("header.passwordResetPopup.reset")}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth, I18n} from 'aws-amplify'
export default {
  components: {},

  // ------------------------------------ props ------------------------------------
  props: {},

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      mode: null,
      email: null,
      sent_email: null,
      code: null,
      new_password: null,
      isError: false
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    passwordreset_visibled() {
      return this.mode === "passwordreset"
    },
    verify_visibled() {
      return this.mode === "verify"
    }
  },

  // ------------------------------------ watch ------------------------------------
  watch: {},


  // ------------------------------------ hook ------------------------------------
  created() {
    // モード
    this.mode = "passwordreset"

    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("keydown", this.onKeydown, false)
  },

  beforeUnmount() {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("keydown", this.onKeydown, false)
  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    // キーダウンイベント
    onKeydown(event) {
      // Escapeボタン押下時
      if (event.key === "Escape") {
        this.closePopup()
      }
    },

    // ポップアップを閉じる
    closePopup: async function(){
      this.$emit("close-popup")
    },

    // ログインへ
    gotoLogin: async function(){
      this.$emit("goto-login")
    },

    // モード変更
    changeMode: async function(mode) {
      this.mode = mode
    },

    // リセットコード送信
    sendResetCode: async function(){
      this.isError = false
      this.sent_email = this.email
      try {
        await Auth.forgotPassword(this.sent_email)
      } catch (error) {
        // エラー
        console.error('error signing in', error);
        this.$toast.error(this.$t('msg.passwordReset.failSendResetCode', {err : I18n.get(error.message)}));
        this.isError = true
        return
      }
      this.changeMode("verify")
    },

    // 認証＆リセット
    verifyAndReset: async function(){
      this.isError = false
      try {
        await Auth.forgotPasswordSubmit(
          this.sent_email,
          this.code,
          this.new_password
        );
      } catch (error) {
        console.error('error confirming sign up', error);
        this.$toast.error(this.$t('msg.passwordReset.failResetPassword', {err : I18n.get(error.message)}));
        this.isError = true
        return
      }
      
      // ポップアップを閉じる
      await this.closePopup()
      this.$toast.info(this.$t('msg.passwordReset.success'));

      // ログインへ
      await this.gotoLogin()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
