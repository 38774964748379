<template>
  <div class="main-view">
    <div class="local">
      <p>LINK</p>
      <router-link to="/mypage/">{{$t("title.myPage")}}</router-link> |
      <router-link to="/admin/pictureBook">{{$t("title.editPictureBook")}}</router-link>
      <hr>
      <p>LP</p>
      <div style="display: block">
        <li v-for="item in pictureBook" :key="item.id">
          <a :href="'/pb/'+item.questionCode">{{ item.name }}:{{ item.questionCode }}</a>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listPictureBooks
} from '@/graphql/queries'
import { API } from 'aws-amplify'

export default {
  name: 'MainView',

  props: {
    isLogin: {
      type: Boolean,
      require: true,
    }
  },
  data() {
    return {
      pictureBook: [],
    }
  },
  computed: {
  },

  created() {
    try{
      API.graphql(
          {
            query: listPictureBooks,
            authMode: this.isLogin ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM"
          }).then(value => {
            this.pictureBook = value.data.listPictureBooks.items
          }
      )
    } catch(error) {
      console.error("list", error)
      return
    }
  }
}
</script>

<style scoped lang="scss">
.local {
  text-align: center;
  font-size: 20px;
}
</style>