<template>
  <div class="picture-book-view">
    <div class="container">
      <!--アプリの説明セクション-->
      <section class="explanation">
        <div class="explanation__points">
          <p>{{ $t("lp.explanation") }}</p>
        </div>
      </section>

      <!--絵の表示セクション-->
      <section class="picture">
        <img :src="photoURL" class="picture__main-pic" />
      </section>

      <!--絵本の感想を記入するセクション-->
      <LpComment
        :isChildrenMode="isChildrenMode"
        :pictureBook="pictureBook"
        @on-change-children-mode="onChangeChildrenMode"
        @onInputChildrenAnswer1="onInputChildrenAnswer1"
        @onInputChildrenAnswer2="onInputChildrenAnswer2"
        @onInputGrownUpAnswer1="onInputGrownUpAnswer1"
        @onInputGrownUpAnswer2="onInputGrownUpAnswer2"
      />

      <!--今日の気持ち -->
      <section class="emotion">
        <div class="emotion__title">{{ $t("lp.emotionTitle") }}</div>
        <div
          class="emotion__container cursor-pointer"
          @click="openEmotionPopup"
        >
          <img
            :src="emotionIconPath"
            class="emotion__emoticon popupEmoShow"
            alt="neutral"
          />
        </div>
      </section>

      <!--テンションメーター-->
      <section class="uplift">
        <img src="@/assets/img/arrowdown.png" class="uplift__arrowdown" />
        <label for="upliftMeter" class="uplift__meter-title">{{
          $t("lp.upliftMeterTitle")
        }}</label>
        <input
          type="range"
          list="tickmarks"
          class="uplift__meter"
          name="upliftMeter"
          min="-10"
          max="10"
          step="1"
          v-model="emotionalPoint"
        />
        <img src="@/assets/img/arrowup.png" class="uplift__arrowup" />
        <p class="uplift__score">{{ emotionalPoint }}</p>
        <p class="uplift__minus">-10</p>
        <p class="uplift__zero">{{ $t("lp.upliftZero") }}</p>
        <p class="uplift__plus">+10</p>
      </section>

      <!--アカウント関連の機能の実装-->
      <section class="user" id="user">
        <!-- ログイン済みの場合 -->
        <div v-if="isLogin">
          <!-- 子供モードの場合 -->
          <div v-if="isChildrenMode">
            <!-- 子供が1人のみの場合 -->
            <div v-if="hasOnlyOneChild" class="user__substitute">
              <p class="user__substitute__text">
                {{
                  $t("lp.comment.representative", { name: children[0].name })
                }}
              </p>
            </div>
            <!-- 子供が2人以上の場合 -->
            <div v-else class="user__substitute">
              <p class="user__substitute__text">
                {{ $t("lp.comment.insteadTitle2") }}
              </p>
              <label v-for="(child, index) in children" :key="index">
                <span>
                  <input
                    :id="'child' + index"
                    v-model="postChildId"
                    type="radio"
                    :name="'child' + index"
                    :value="child.id"
                  />
                  {{ $t("lp.comment.representative", { name: child.name }) }}
                </span>
              </label>
            </div>
          </div>
          <div class="user__save" @click="saveMypage">
            <button class="user__save__btn">
              {{ $t("lp.submitToMyPage") }}
            </button>
          </div>
        </div>
        <!-- 未ログインの場合 -->
        <div v-else>
          <!-- サインアップポップアップ起動 -->
          <div class="user__login">
            <button class="user__login__btn" @click="openSignupPopup">{{$t("lp.signup")}}</button>
          </div>
          <!-- ログインポップアップの起動 -->
          <div class="user__signup">
            <div class="user__signup__options">
              <p>
                <span class="highlight" @click="openLoginPopup">{{$t("lp.login")}}</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <!--使い方のセクション-->
      <section class="keypoints">
        <div class="keypoints__title">
          <p>{{ $t("manual.title") }}</p>
        </div>
        <div class="keypoints__point">
          <div class="keypoints__point__card1 keypoints__point__card">
            <p class="keypoints__point__card1__title">
              {{ $t("manual.step1.title") }}
            </p>
            <div class="keypoints__point__card1__imgcontainer">
              <img
                src="@/assets/img/readbook.png"
                alt="logo"
                class="keypoints__point__card1__image"
              />
            </div>
            <div class="keypoints__point__card1__text">
              <ul>
                <li>{{ $t("manual.step1.l1") }}</li>
                <li>{{ $t("manual.step1.l2") }}</li>
                <li>{{ $t("manual.step1.l3") }}</li>
              </ul>
            </div>
          </div>
          <div class="keypoints__point__card2 keypoints__point__card">
            <p class="keypoints__point__card1__title">
              {{ $t("manual.step2.title") }}
            </p>
            <img
              src="@/assets/img/post.png"
              alt="logo"
              class="keypoints__point__card2__image"
            />
            <div class="keypoints__point__card2__text">
              <ul>
                <li>{{ $t("manual.step2.l1") }}</li>
                <li>{{ $t("manual.step2.l2") }}</li>
              </ul>
            </div>
          </div>
          <div class="keypoints__point__card3 keypoints__point__card">
            <p class="keypoints__point__card1__title">
              {{ $t("manual.step3.title") }}
            </p>
            <img
              src="@/assets/img/share.png"
              alt="logo"
              class="keypoints__point__card3__image"
            />
            <div class="keypoints__point__card3__text">
              <ul>
                <li>{{ $t("manual.step3.l1") }}</li>
                <li>{{ $t("manual.step3.l2") }}</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <!-- 感情ポップアップ -->
      <LpEmotionPopup
        v-if="isEmotionPopupVislble"
        @close-popup="closeEmotionPopup"
        @select-emotional-type="selectEmotionalType"
      />
    </div>
  </div>
</template>

<script>
import LpComment from "@/components/local/lp/LpComment";
import LpEmotionPopup from "@/components/local/lp/LpEmotionPopup";
import { ContentType, EmotionalType } from "@/models/index";
import moment from "moment"
import { API } from 'aws-amplify'
import { listPictureBooks } from '@/graphql/queries'
import { createPost } from '@/graphql/mutations'
import awsconfig from "../aws-exports";

export default {
  components: {
    LpComment,
    LpEmotionPopup,
  },

  // ------------------------------------ props ------------------------------------
  props: {
    // ユーザー（DB）
    user: {
      type: Object,
      require: true,
    },
    // 子供（DB）
    children: {
      type: Array,
      require: true,
    },
    isLogin: {
      type: Boolean,
      require: true,
    },
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      // 子供モード
      isChildrenMode: true,
      postChildId: null,

      // 絵本
      pictureBook: {},

      // コメント欄
      answerForBasicQuestion: null,
      answerFreeComment: null,
      answerParentQuestion: null,
      answerParentComment: null,

      // 感情タイプ
      emotionalType: EmotionalType.NEUTRAL,
      // テンション
      emotionalPoint: 0,

      // 各ポップアップの表示制御
      isEmotionPopupVislble: false,
    };
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    photoURL() {
      if (!this.pictureBook || !this.pictureBook.picturePath || typeof(this.pictureBook.picturePath) === "undefined") {
        return null
      }
      return `https://${awsconfig.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${this.pictureBook.picturePath}`
    },

    emotionIconPath() {
      return require("@/assets/img/emoji-high/" +
        EmotionalType[this.emotionalType] +
        ".png");
    },

    // 子供は1人
    hasOnlyOneChild() {
      return this.children.length == 1;
    },
  },

  // ------------------------------------ watch ------------------------------------
  watch: {
    isLogin(now) {
      if (now) {
        this.postChildId = this.children[0].id;
      } else {
        this.postChildId = null;
      }
    },
  },

  // ------------------------------------ hook ------------------------------------
  created() {
    // 初期処理
    this.initialize();
  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    // 初期処理
    async initialize() {
      // 検索
      await this.get();
      // 代理投稿の子供ID
      if (this.isLogin) {
        this.postChildId = this.children[0].id;
      } else {
        this.postChildId = null;
      }
    },

    // 子供モードの変更
    onChangeChildrenMode(value) {
      this.isChildrenMode = value;
    },
    // コメントテキストの反映
    onInputChildrenAnswer1(value) {
      this.answerForBasicQuestion = value;
    },
    onInputChildrenAnswer2(value) {
      this.answerFreeComment = value;
    },
    onInputGrownUpAnswer1(value) {
      this.answerParentQuestion = value;
    },
    onInputGrownUpAnswer2(value) {
      this.answerParentComment = value;
    },

    // エモートポップアップの選択
    selectEmotionalType(emotionalType) {
      this.emotionalType = emotionalType;
    },
    // エモートポップアップの開閉
    openEmotionPopup() {
      this.isEmotionPopupVislble = true;
    },
    closeEmotionPopup() {
      this.isEmotionPopupVislble = false;
    },

    // マイページ保存
    saveMypage: async function(){
      if(this.isChildrenMode && this.postChildId ==""){
        this.$toast.info(this.$t('msg.lp.selectChild'));
      }
      // 保存
      this.entry();
    },

    // ログインポップアップ起動
    openLoginPopup: async function(){
      this.$emit("open-login-popup")
    },

    // サインアップポップアップ起動
    openSignupPopup: async function(){
      this.$emit("open-signup-popup")
    },

    get: async function(){
      try{
        // 絵本データ取得
        let filter = {
          questionCode: {
            eq: this.$route.params.questionCode,
          },
        };
        await API.graphql({
          query: listPictureBooks,
          variables: { filter: filter },
          authMode: this.isLogin ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
        }).then((value) => {
          // itemsの中身チェック
          const items = value.data.listPictureBooks.items;
          if (items.length == 1) {
            this.pictureBook = items[0];
          } else {
            this.$router.push({
              name: "notfound",
            });
          }
        });
      } catch (error) {
        console.error("list", error);
        return;
      }

      // タイトルの変更
      let title;
      title = "「" + this.pictureBook.name + "」のページ";
      this.$emit("change-title", title);
      // descriptionの変更
      let description;
      description = "「" + this.pictureBook.name + "」のページ";
      this.$emit("change-description", description);
    },

    // マイページ保存
    entry: async function() {
      let post = {};

      // 子供の記録モードの場合
      if (this.isChildrenMode) {
        post = {
          postedAt: moment().format(),
          userId: this.user.id,
          childId: this.postChildId,
          contentType: ContentType.PICTURE_BOOK,
          pictureBookId: this.pictureBook.id,
          answerForBasicQuestion: this.answerForBasicQuestion,
          answerFreeComment: this.answerFreeComment,
          answerParentQuestion: null,
          answerParentComment: null,
          emotionalPoint: this.emotionalPoint,
          emotionalType: this.emotionalType,
        };
      } else {
        // 大人モードの場合
        post = {
          postedAt: moment().format(),
          userId: this.user.id,
          childId: null,
          contentType: ContentType.PICTURE_BOOK,
          pictureBookId: this.pictureBook.id,
          answerForBasicQuestion: null,
          answerFreeComment: null,
          answerParentQuestion: this.answerParentQuestion,
          answerParentComment: this.answerParentComment,
          emotionalPoint: this.emotionalPoint,
          emotionalType: this.emotionalType,
        };
      }
      await API.graphql({
        query: createPost,
        variables: { input: post },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      }).catch((err) => {
        console.error("entry error", err);
        return;
      });

      // ユーザー情報再取得
      this.$emit("reget-user-info")

      this.$toast.info(this.$t("msg.lp.posted"));
      this.$router.push({
        name: "MypageView",
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
