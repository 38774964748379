<template>
  <div id="app">
    <!--ナビゲーションバー-->
    <NavigationBar
      ref="navigationbar"
      :start_render_content="start_render_content"
      :user_cognite_info="user_cognite_info"
      :user="user"
      :children="children"
      :latestPost="latestPost"
      @login-completed="loginCompleted"
      @logout-completed="logoutCompleted"
      @userinfo-update-completed="userinfoUpdateCompleted"
      @mail-address-update-completed="mailAddressUpdateCompleted"
    />

    <!-- メイン部分 -->
    <router-view
      v-if="start_render_content"
      class="main"
      :user="user"
      :children="children"
      :isLogin="isLogin"
      @open-login-popup="openLoginPopup"
      @open-signup-popup="openSignupPopup"
      @reget-user-info="regetUserInfo"
      @change-title="changeTitle"
      @change-description="changeDescription"
    />

    <!--フッター-->
    <footer class="footer">
      <div class="footer__title">
        <a :href="$t('page.extra_url.pictureBook')" class="toppage">{{$t("footer.topPage")}}</a></div>
      <a :href="$t('page.extra_url.disclaimer')" class="footer__disclaimer">{{$t("footer.disclaimerCopyright")}}</a>
      <a :href="$t('page.extra_url.privacy_policy')" class="footer__privacy">{{$t("footer.privacyPolicy")}}</a>
    </footer>
  </div>
</template>

<script>
import NavigationBar from "@/components/global/navigation/NavigationBar"
import { Auth, API, graphqlOperation } from 'aws-amplify'
import {
  listUsers,
  listChildren,
  listPosts,
  listPostsSortByPostedAt
} from '@/graphql/queries'

export default {
  name: "App",

  components: {
    NavigationBar
  },

  // ------------------------------------ data ------------------------------------
  data() {
    return {
      // ユーザー情報（cognito）
      user_cognite_info: null,
      // ユーザー（DB）
      user: null,
      // 子供（DB）
      children: [],
      // 最新の投稿
      latestPost: null,

      // ログイン状態かどうか。ユーザ、子供の情報も含め検索できたことを確認
      isLogin: false,

      // 子コンポーネントのレンダリングを開始するか
      start_render_content: false
    }
  },

  // ------------------------------------ computed ------------------------------------
  computed: {
    // サインイン状態
    isSignined() {
      return !!(this.user_cognite_info)
    }
  },

  // ------------------------------------ hook ------------------------------------
  created: async function(){
    // ユーザー情報取得＆最新投稿
    await this.get_userinfo_latestpost()
    this.start_render_content = true
  },

  mounted() {
    // タイトル変更
    const DEFAULT_TITLE = 'toybox'
    const DEFAULT_DESCRIPTION = 'toybox'
    let tempTitle = null
    let tempDescription = null
    this.$router.afterEach((to) => {
      if (!to.meta) {
        tempTitle = DEFAULT_TITLE
        tempDescription = DEFAULT_DESCRIPTION
      } else {
        tempTitle = to.meta.title || DEFAULT_TITLE
        tempDescription = to.meta.desc || DEFAULT_DESCRIPTION
      }
      // 各種設定
      this.changeTitle(tempTitle)
      this.changeDescription(tempDescription)
    })
  },

  // ------------------------------------ methods ------------------------------------
  methods: {
    // ログイン完了検知
    loginCompleted: async function(){
      // ユーザー情報取得＆最新投稿
      await this.get_userinfo_latestpost()
    },
  
    // ログアウト完了検知
    logoutCompleted: async function(){
      // ユーザー情報取得＆最新投稿
      await this.get_userinfo_latestpost()
    },

    // ユーザー情報更新完了検知
    userinfoUpdateCompleted(){
      // ユーザー情報取得＆最新投稿
      this.get_userinfo_latestpost()
    },

    // メールアドレス更新完了検知
    mailAddressUpdateCompleted(){
      // ユーザー情報取得＆最新投稿
      this.get_userinfo_latestpost()
    },

    // ユーザー情報再取得
    regetUserInfo() {
      // ユーザー情報取得＆最新投稿
      this.get_userinfo_latestpost()
    },


    // タイトルの変更
    changeTitle(key_title){
      document.title = this.$t(key_title)
    },

    // descriptionの変更
    changeDescription(key_description){
      const description = this.$t(key_description)

      if (document.querySelector("meta[name='description']")) {
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', description);
      }
      if (document.querySelector("meta[property='og:description']")) {
        document
          .querySelector("meta[property='og:description']")
          .setAttribute('content', description)
      }
    },

    // ログインポップアップオープン
    openLoginPopup(){
      // 未ログイン状態の場合、ログインポップアップ表示
      if (!this.isSignined) {
        this.$refs["navigationbar"].openLoginPopup()
      }
    },

    // サインアップポップアップオープン
    openSignupPopup(){
      // 未ログイン状態の場合、サインアップポップアップ表示
      if (!this.isSignined) {
        this.$refs["navigationbar"].openSignupPopup()
      }
    },

    // ユーザー情報取得＆最新投稿
    get_userinfo_latestpost: async function(){
      // 資格情報更新
      await Auth.currentCredentials().then();
      const value = await Auth.currentUserInfo();
      this.user_cognite_info = value ? value.attributes : null;

      // サインイン状態の場合：ユーザー/子供情報取得/直近投稿取得
      if (this.isSignined) {
        try{
          // ユーザー取得
          await API.graphql(
            {
              query: listUsers,
              authMode: "AMAZON_COGNITO_USER_POOLS"
            }).then( value => {
              this.user = value.data.listUsers.items[0]
            }
          )

          // 子供取得
          await API.graphql(
            {
              query: listChildren,
              authMode: "AMAZON_COGNITO_USER_POOLS"
            }).then( value => {
              this.children = value.data.listChildren.items.sort((a,b) =>{
                // sort by createdAt of child
                const createdA = a.createdAt;
                const createdB = b.createdAt;
                let comparison = 0;
                if (createdA > createdB) {
                  comparison = 1;
                } else if (createdA < createdB) {
                  comparison = -1;
                }
                return comparison;
              })
            }
          )

          // 子供が投稿しているかどうかの情報取得
          for (const child of this.children) {
            let filter = {
              childId: {
                eq: child.id
              }
            };
            await API.graphql(
              {
                query: listPosts,
                variables: {filter: filter},
                authMode: "AMAZON_COGNITO_USER_POOLS"
              }).then( value => {
                // itemsの中身チェック
                const items = value.data.listPosts.items
                if (items.length > 0) {
                  child.hasPosts = true
                } else {
                  child.hasPosts = false
                }
              }
            )
          }

          // 最新投稿の取得
          await API.graphql(graphqlOperation(listPostsSortByPostedAt, {
            userId : this.user.id,
            sortDirection: "DESC",
            limit: 1,
          })).then( value => {
            // itemsの中身チェック
            const items = value.data.listPostsSortByPostedAt.items

            // 投稿が存在しない場合
            if (items.length == 0) {
              this.latestPost = null
            // 投稿が存在した場合
            } else {
              this.latestPost = items[0]
            }
          })
        } catch(error) {
          console.error('list',error)
          return
        }
      }

      this.isLogin = this.isSignined
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  margin-top: 7rem;
}
</style>
