/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      mailAddress
      lastName
      firstName
      nickName
      userAlias
      children {
        items {
          id
          userId
          name
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      posts {
        items {
          id
          postedAt
          userId
          childId
          contentType
          pictureBookId
          answerForBasicQuestion
          answerFreeComment
          answerParentQuestion
          answerParentComment
          emotionalPoint
          emotionalType
          optionContent
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mailAddress
        lastName
        firstName
        nickName
        userAlias
        children {
          nextToken
        }
        posts {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getChild = /* GraphQL */ `
  query GetChild($id: ID!) {
    getChild(id: $id) {
      id
      userId
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listChildren = /* GraphQL */ `
  query ListChildren(
    $filter: ModelChildFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChildren(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      postedAt
      userId
      childId
      child {
        id
        userId
        name
        createdAt
        updatedAt
        owner
      }
      contentType
      pictureBookId
      answerForBasicQuestion
      answerFreeComment
      answerParentQuestion
      answerParentComment
      emotionalPoint
      emotionalType
      optionContent
      createdAt
      updatedAt
      pictureBook {
        id
        questionCode
        name
        picturePath
        basicQuestion
        parentQuestion
        freeCommentQuestion
        parentCommentQuestion
        exampleBasicQuestion
        exampleFreeComment
        exampleParentQuestion
        exampleParentComment
        createdAt
        updatedAt
      }
      owner
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postedAt
        userId
        childId
        child {
          id
          userId
          name
          createdAt
          updatedAt
          owner
        }
        contentType
        pictureBookId
        answerForBasicQuestion
        answerFreeComment
        answerParentQuestion
        answerParentComment
        emotionalPoint
        emotionalType
        optionContent
        createdAt
        updatedAt
        pictureBook {
          id
          questionCode
          name
          picturePath
          basicQuestion
          parentQuestion
          freeCommentQuestion
          parentCommentQuestion
          exampleBasicQuestion
          exampleFreeComment
          exampleParentQuestion
          exampleParentComment
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const listPostsSortByPostedAt = /* GraphQL */ `
  query ListPostsSortByPostedAt(
    $userId: ID
    $postedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostsSortByPostedAt(
      userId: $userId
      postedAt: $postedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postedAt
        userId
        childId
        child {
          id
          userId
          name
          createdAt
          updatedAt
          owner
        }
        contentType
        pictureBookId
        answerForBasicQuestion
        answerFreeComment
        answerParentQuestion
        answerParentComment
        emotionalPoint
        emotionalType
        optionContent
        createdAt
        updatedAt
        pictureBook {
          id
          questionCode
          name
          picturePath
          basicQuestion
          parentQuestion
          freeCommentQuestion
          parentCommentQuestion
          exampleBasicQuestion
          exampleFreeComment
          exampleParentQuestion
          exampleParentComment
          createdAt
          updatedAt
        }
        owner
      }
      nextToken
    }
  }
`;
export const getPictureBook = /* GraphQL */ `
  query GetPictureBook($id: ID!) {
    getPictureBook(id: $id) {
      id
      questionCode
      name
      picturePath
      basicQuestion
      parentQuestion
      freeCommentQuestion
      parentCommentQuestion
      exampleBasicQuestion
      exampleFreeComment
      exampleParentQuestion
      exampleParentComment
      createdAt
      updatedAt
    }
  }
`;
export const listPictureBooks = /* GraphQL */ `
  query ListPictureBooks(
    $filter: ModelPictureBookFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPictureBooks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionCode
        name
        picturePath
        basicQuestion
        parentQuestion
        freeCommentQuestion
        parentCommentQuestion
        exampleBasicQuestion
        exampleFreeComment
        exampleParentQuestion
        exampleParentComment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
